@charset "UTF-8";
@import url('../node_modules/material-design-icons/iconfont/material-icons.css');

/**
 * material-design-lite - Material Design Components in CSS, JS and HTML
 * @version v1.3.0
 * @license Apache-2.0
 * @copyright 2015 Google, Inc.
 * @link https://github.com/google/material-design-lite
 */
html {
  color: rgba(0, 0, 0, 0.87);
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, *:before, *:after, *:first-letter {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu, .mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

main {
  display: block;
}

*[hidden] {
  display: none !important;
}

html, body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -.02em;
  opacity: .54;
  font-size: .6em;
}

h1 {
  font-size: 56px;
  line-height: 1.35;
  letter-spacing: -.02em;
  margin: 24px 0;
}

h1, h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

h2 {
  font-size: 45px;
  line-height: 48px;
}

h2, h3 {
  margin: 24px 0;
}

h3 {
  font-size: 34px;
  line-height: 40px;
}

h3, h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

h4 {
  font-size: 24px;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin: 24px 0 16px;
}

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .02em;
}

h5, h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 24px 0 16px;
}

h6 {
  font-size: 16px;
  letter-spacing: .04em;
}

h6, p {
  font-weight: 400;
  line-height: 24px;
}

p {
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 0 16px;
}

a {
  color: #2f2f2f;
  font-weight: 500;
}

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: .08em;
}

blockquote:before {
  position: absolute;
  left: -.5em;
  content: '“';
}

blockquote:after {
  content: '”';
  margin-left: -.05em;
}

mark {
  background-color: #f4ff81;
}

dt {
  font-weight: 700;
}

address {
  font-size: 12px;
  line-height: 1;
  font-style: normal;
}

address, ul, ol {
  font-weight: 400;
  letter-spacing: 0;
}

ul, ol {
  font-size: 14px;
  line-height: 24px;
}

.mdl-typography--display-4, .mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.04em;
}

.mdl-typography--display-4-color-contrast {
  opacity: .54;
}

.mdl-typography--display-3, .mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -.02em;
}

.mdl-typography--display-3-color-contrast {
  opacity: .54;
}

.mdl-typography--display-2, .mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
}

.mdl-typography--display-2-color-contrast {
  opacity: .54;
}

.mdl-typography--display-1, .mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.mdl-typography--display-1-color-contrast {
  opacity: .54;
}

.mdl-typography--headline, .mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
}

.mdl-typography--headline-color-contrast {
  opacity: .87;
}

.mdl-typography--title, .mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .02em;
}

.mdl-typography--title-color-contrast {
  opacity: .87;
}

.mdl-typography--subhead, .mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: .04em;
}

.mdl-typography--subhead-color-contrast {
  opacity: .87;
}

.mdl-typography--body-2, .mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-color-contrast {
  opacity: .87;
}

.mdl-typography--body-1, .mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-color-contrast {
  opacity: .87;
}

.mdl-typography--body-2-force-preferred-font, .mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-force-preferred-font-color-contrast {
  opacity: .87;
}

.mdl-typography--body-1-force-preferred-font, .mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-force-preferred-font-color-contrast {
  opacity: .87;
}

.mdl-typography--caption, .mdl-typography--caption-force-preferred-font {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.mdl-typography--caption-color-contrast, .mdl-typography--caption-force-preferred-font-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: .54;
}

.mdl-typography--caption-force-preferred-font-color-contrast, .mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.mdl-typography--menu {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--menu-color-contrast {
  opacity: .87;
}

.mdl-typography--menu-color-contrast, .mdl-typography--button, .mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--button, .mdl-typography--button-color-contrast {
  text-transform: uppercase;
}

.mdl-typography--button-color-contrast {
  opacity: .87;
}

.mdl-typography--text-left {
  text-align: left;
}

.mdl-typography--text-right {
  text-align: right;
}

.mdl-typography--text-center {
  text-align: center;
}

.mdl-typography--text-justify {
  text-align: justify;
}

.mdl-typography--text-nowrap {
  white-space: nowrap;
}

.mdl-typography--text-lowercase {
  text-transform: lowercase;
}

.mdl-typography--text-uppercase {
  text-transform: uppercase;
}

.mdl-typography--text-capitalize {
  text-transform: capitalize;
}

.mdl-typography--font-thin {
  font-weight: 200 !important;
}

.mdl-typography--font-light {
  font-weight: 300 !important;
}

.mdl-typography--font-regular {
  font-weight: 400 !important;
}

.mdl-typography--font-medium {
  font-weight: 500 !important;
}

.mdl-typography--font-bold {
  font-weight: 700 !important;
}

.mdl-typography--font-black {
  font-weight: 900 !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mdl-color-text--red {
  color: #f44336 !important;
}

.mdl-color--red {
  background-color: #f44336 !important;
}

.mdl-color-text--red-50 {
  color: #ffebee !important;
}

.mdl-color--red-50 {
  background-color: #ffebee !important;
}

.mdl-color-text--red-100 {
  color: #ffcdd2 !important;
}

.mdl-color--red-100 {
  background-color: #ffcdd2 !important;
}

.mdl-color-text--red-200 {
  color: #ef9a9a !important;
}

.mdl-color--red-200 {
  background-color: #ef9a9a !important;
}

.mdl-color-text--red-300 {
  color: #e57373 !important;
}

.mdl-color--red-300 {
  background-color: #e57373 !important;
}

.mdl-color-text--red-400 {
  color: #ef5350 !important;
}

.mdl-color--red-400 {
  background-color: #ef5350 !important;
}

.mdl-color-text--red-500 {
  color: #f44336 !important;
}

.mdl-color--red-500 {
  background-color: #f44336 !important;
}

.mdl-color-text--red-600 {
  color: #e53935 !important;
}

.mdl-color--red-600 {
  background-color: #e53935 !important;
}

.mdl-color-text--red-700 {
  color: #d32f2f !important;
}

.mdl-color--red-700 {
  background-color: #d32f2f !important;
}

.mdl-color-text--red-800 {
  color: #c62828 !important;
}

.mdl-color--red-800 {
  background-color: #c62828 !important;
}

.mdl-color-text--red-900 {
  color: #b71c1c !important;
}

.mdl-color--red-900 {
  background-color: #b71c1c !important;
}

.mdl-color-text--red-A100 {
  color: #ff8a80 !important;
}

.mdl-color--red-A100 {
  background-color: #ff8a80 !important;
}

.mdl-color-text--red-A200 {
  color: #ff5252 !important;
}

.mdl-color--red-A200 {
  background-color: #ff5252 !important;
}

.mdl-color-text--red-A400 {
  color: #ff1744 !important;
}

.mdl-color--red-A400 {
  background-color: #ff1744 !important;
}

.mdl-color-text--red-A700 {
  color: #e11c41 !important;
}

.mdl-color--red-A700 {
  background-color: #e11c41 !important;
}

.mdl-color-text--pink {
  color: #e91e63 !important;
}

.mdl-color--pink {
  background-color: #e91e63 !important;
}

.mdl-color-text--pink-50 {
  color: #fce4ec !important;
}

.mdl-color--pink-50 {
  background-color: #fce4ec !important;
}

.mdl-color-text--pink-100 {
  color: #f8bbd0 !important;
}

.mdl-color--pink-100 {
  background-color: #f8bbd0 !important;
}

.mdl-color-text--pink-200 {
  color: #f48fb1 !important;
}

.mdl-color--pink-200 {
  background-color: #f48fb1 !important;
}

.mdl-color-text--pink-300 {
  color: #f06292 !important;
}

.mdl-color--pink-300 {
  background-color: #f06292 !important;
}

.mdl-color-text--pink-400 {
  color: #ec407a !important;
}

.mdl-color--pink-400 {
  background-color: #ec407a !important;
}

.mdl-color-text--pink-500 {
  color: #e91e63 !important;
}

.mdl-color--pink-500 {
  background-color: #e91e63 !important;
}

.mdl-color-text--pink-600 {
  color: #d81b60 !important;
}

.mdl-color--pink-600 {
  background-color: #d81b60 !important;
}

.mdl-color-text--pink-700 {
  color: #c2185b !important;
}

.mdl-color--pink-700 {
  background-color: #c2185b !important;
}

.mdl-color-text--pink-800 {
  color: #ad1457 !important;
}

.mdl-color--pink-800 {
  background-color: #ad1457 !important;
}

.mdl-color-text--pink-900 {
  color: #880e4f !important;
}

.mdl-color--pink-900 {
  background-color: #880e4f !important;
}

.mdl-color-text--pink-A100 {
  color: #ff80ab !important;
}

.mdl-color--pink-A100 {
  background-color: #ff80ab !important;
}

.mdl-color-text--pink-A200 {
  color: #2f2f2f !important;
}

.mdl-color--pink-A200 {
  background-color: #2f2f2f !important;
}

.mdl-color-text--pink-A400 {
  color: #f50057 !important;
}

.mdl-color--pink-A400 {
  background-color: #f50057 !important;
}

.mdl-color-text--pink-A700 {
  color: #c51162 !important;
}

.mdl-color--pink-A700 {
  background-color: #c51162 !important;
}

.mdl-color-text--purple {
  color: #9c27b0 !important;
}

.mdl-color--purple {
  background-color: #9c27b0 !important;
}

.mdl-color-text--purple-50 {
  color: #f3e5f5 !important;
}

.mdl-color--purple-50 {
  background-color: #f3e5f5 !important;
}

.mdl-color-text--purple-100 {
  color: #e1bee7 !important;
}

.mdl-color--purple-100 {
  background-color: #e1bee7 !important;
}

.mdl-color-text--purple-200 {
  color: #ce93d8 !important;
}

.mdl-color--purple-200 {
  background-color: #ce93d8 !important;
}

.mdl-color-text--purple-300 {
  color: #ba68c8 !important;
}

.mdl-color--purple-300 {
  background-color: #ba68c8 !important;
}

.mdl-color-text--purple-400 {
  color: #ab47bc !important;
}

.mdl-color--purple-400 {
  background-color: #ab47bc !important;
}

.mdl-color-text--purple-500 {
  color: #9c27b0 !important;
}

.mdl-color--purple-500 {
  background-color: #9c27b0 !important;
}

.mdl-color-text--purple-600 {
  color: #8e24aa !important;
}

.mdl-color--purple-600 {
  background-color: #8e24aa !important;
}

.mdl-color-text--purple-700 {
  color: #7b1fa2 !important;
}

.mdl-color--purple-700 {
  background-color: #7b1fa2 !important;
}

.mdl-color-text--purple-800 {
  color: #6a1b9a !important;
}

.mdl-color--purple-800 {
  background-color: #6a1b9a !important;
}

.mdl-color-text--purple-900 {
  color: #4a148c !important;
}

.mdl-color--purple-900 {
  background-color: #4a148c !important;
}

.mdl-color-text--purple-A100 {
  color: #ea80fc !important;
}

.mdl-color--purple-A100 {
  background-color: #ea80fc !important;
}

.mdl-color-text--purple-A200 {
  color: #e040fb !important;
}

.mdl-color--purple-A200 {
  background-color: #e040fb !important;
}

.mdl-color-text--purple-A400 {
  color: #d500f9 !important;
}

.mdl-color--purple-A400 {
  background-color: #d500f9 !important;
}

.mdl-color-text--purple-A700 {
  color: #a0f !important;
}

.mdl-color--purple-A700 {
  background-color: #a0f !important;
}

.mdl-color-text--deep-purple {
  color: #673ab7 !important;
}

.mdl-color--deep-purple {
  background-color: #673ab7 !important;
}

.mdl-color-text--deep-purple-50 {
  color: #ede7f6 !important;
}

.mdl-color--deep-purple-50 {
  background-color: #ede7f6 !important;
}

.mdl-color-text--deep-purple-100 {
  color: #d1c4e9 !important;
}

.mdl-color--deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.mdl-color-text--deep-purple-200 {
  color: #b39ddb !important;
}

.mdl-color--deep-purple-200 {
  background-color: #b39ddb !important;
}

.mdl-color-text--deep-purple-300 {
  color: #9575cd !important;
}

.mdl-color--deep-purple-300 {
  background-color: #9575cd !important;
}

.mdl-color-text--deep-purple-400 {
  color: #7e57c2 !important;
}

.mdl-color--deep-purple-400 {
  background-color: #7e57c2 !important;
}

.mdl-color-text--deep-purple-500 {
  color: #673ab7 !important;
}

.mdl-color--deep-purple-500 {
  background-color: #673ab7 !important;
}

.mdl-color-text--deep-purple-600 {
  color: #5e35b1 !important;
}

.mdl-color--deep-purple-600 {
  background-color: #5e35b1 !important;
}

.mdl-color-text--deep-purple-700 {
  color: #512da8 !important;
}

.mdl-color--deep-purple-700 {
  background-color: #512da8 !important;
}

.mdl-color-text--deep-purple-800 {
  color: #4527a0 !important;
}

.mdl-color--deep-purple-800 {
  background-color: #4527a0 !important;
}

.mdl-color-text--deep-purple-900 {
  color: #311b92 !important;
}

.mdl-color--deep-purple-900 {
  background-color: #311b92 !important;
}

.mdl-color-text--deep-purple-A100 {
  color: #b388ff !important;
}

.mdl-color--deep-purple-A100 {
  background-color: #b388ff !important;
}

.mdl-color-text--deep-purple-A200 {
  color: #7c4dff !important;
}

.mdl-color--deep-purple-A200 {
  background-color: #7c4dff !important;
}

.mdl-color-text--deep-purple-A400 {
  color: #651fff !important;
}

.mdl-color--deep-purple-A400 {
  background-color: #651fff !important;
}

.mdl-color-text--deep-purple-A700 {
  color: #6200ea !important;
}

.mdl-color--deep-purple-A700 {
  background-color: #6200ea !important;
}

.mdl-color-text--indigo {
  color: #3f51b5 !important;
}

.mdl-color--indigo {
  background-color: #3f51b5 !important;
}

.mdl-color-text--indigo-50 {
  color: #e8eaf6 !important;
}

.mdl-color--indigo-50 {
  background-color: #e8eaf6 !important;
}

.mdl-color-text--indigo-100 {
  color: #c5cae9 !important;
}

.mdl-color--indigo-100 {
  background-color: #c5cae9 !important;
}

.mdl-color-text--indigo-200 {
  color: #9fa8da !important;
}

.mdl-color--indigo-200 {
  background-color: #9fa8da !important;
}

.mdl-color-text--indigo-300 {
  color: #7986cb !important;
}

.mdl-color--indigo-300 {
  background-color: #7986cb !important;
}

.mdl-color-text--indigo-400 {
  color: #5c6bc0 !important;
}

.mdl-color--indigo-400 {
  background-color: #5c6bc0 !important;
}

.mdl-color-text--indigo-500 {
  color: #3f51b5 !important;
}

.mdl-color--indigo-500 {
  background-color: #3f51b5 !important;
}

.mdl-color-text--indigo-600 {
  color: #3949ab !important;
}

.mdl-color--indigo-600 {
  background-color: #3949ab !important;
}

.mdl-color-text--indigo-700 {
  color: #303f9f !important;
}

.mdl-color--indigo-700 {
  background-color: #303f9f !important;
}

.mdl-color-text--indigo-800 {
  color: #283593 !important;
}

.mdl-color--indigo-800 {
  background-color: #283593 !important;
}

.mdl-color-text--indigo-900 {
  color: #1a237e !important;
}

.mdl-color--indigo-900 {
  background-color: #1a237e !important;
}

.mdl-color-text--indigo-A100 {
  color: #8c9eff !important;
}

.mdl-color--indigo-A100 {
  background-color: #8c9eff !important;
}

.mdl-color-text--indigo-A200 {
  color: #536dfe !important;
}

.mdl-color--indigo-A200 {
  background-color: #536dfe !important;
}

.mdl-color-text--indigo-A400 {
  color: #3d5afe !important;
}

.mdl-color--indigo-A400 {
  background-color: #3d5afe !important;
}

.mdl-color-text--indigo-A700 {
  color: #304ffe !important;
}

.mdl-color--indigo-A700 {
  background-color: #304ffe !important;
}

.mdl-color-text--blue {
  color: #2196f3 !important;
}

.mdl-color--blue {
  background-color: #2196f3 !important;
}

.mdl-color-text--blue-50 {
  color: #e3f2fd !important;
}

.mdl-color--blue-50 {
  background-color: #e3f2fd !important;
}

.mdl-color-text--blue-100 {
  color: #bbdefb !important;
}

.mdl-color--blue-100 {
  background-color: #bbdefb !important;
}

.mdl-color-text--blue-200 {
  color: #90caf9 !important;
}

.mdl-color--blue-200 {
  background-color: #90caf9 !important;
}

.mdl-color-text--blue-300 {
  color: #64b5f6 !important;
}

.mdl-color--blue-300 {
  background-color: #64b5f6 !important;
}

.mdl-color-text--blue-400 {
  color: #42a5f5 !important;
}

.mdl-color--blue-400 {
  background-color: #42a5f5 !important;
}

.mdl-color-text--blue-500 {
  color: #2196f3 !important;
}

.mdl-color--blue-500 {
  background-color: #2196f3 !important;
}

.mdl-color-text--blue-600 {
  color: #1e88e5 !important;
}

.mdl-color--blue-600 {
  background-color: #1e88e5 !important;
}

.mdl-color-text--blue-700 {
  color: #1976d2 !important;
}

.mdl-color--blue-700 {
  background-color: #1976d2 !important;
}

.mdl-color-text--blue-800 {
  color: #1565c0 !important;
}

.mdl-color--blue-800 {
  background-color: #1565c0 !important;
}

.mdl-color-text--blue-900 {
  color: #0d47a1 !important;
}

.mdl-color--blue-900 {
  background-color: #0d47a1 !important;
}

.mdl-color-text--blue-A100 {
  color: #82b1ff !important;
}

.mdl-color--blue-A100 {
  background-color: #82b1ff !important;
}

.mdl-color-text--blue-A200 {
  color: #448aff !important;
}

.mdl-color--blue-A200 {
  background-color: #448aff !important;
}

.mdl-color-text--blue-A400 {
  color: #2979ff !important;
}

.mdl-color--blue-A400 {
  background-color: #2979ff !important;
}

.mdl-color-text--blue-A700 {
  color: #2962ff !important;
}

.mdl-color--blue-A700 {
  background-color: #2962ff !important;
}

.mdl-color-text--light-blue {
  color: #03a9f4 !important;
}

.mdl-color--light-blue {
  background-color: #03a9f4 !important;
}

.mdl-color-text--light-blue-50 {
  color: #e1f5fe !important;
}

.mdl-color--light-blue-50 {
  background-color: #e1f5fe !important;
}

.mdl-color-text--light-blue-100 {
  color: #b3e5fc !important;
}

.mdl-color--light-blue-100 {
  background-color: #b3e5fc !important;
}

.mdl-color-text--light-blue-200 {
  color: #81d4fa !important;
}

.mdl-color--light-blue-200 {
  background-color: #81d4fa !important;
}

.mdl-color-text--light-blue-300 {
  color: #4fc3f7 !important;
}

.mdl-color--light-blue-300 {
  background-color: #4fc3f7 !important;
}

.mdl-color-text--light-blue-400 {
  color: #29b6f6 !important;
}

.mdl-color--light-blue-400 {
  background-color: #29b6f6 !important;
}

.mdl-color-text--light-blue-500 {
  color: #03a9f4 !important;
}

.mdl-color--light-blue-500 {
  background-color: #03a9f4 !important;
}

.mdl-color-text--light-blue-600 {
  color: #039be5 !important;
}

.mdl-color--light-blue-600 {
  background-color: #039be5 !important;
}

.mdl-color-text--light-blue-700 {
  color: #0288d1 !important;
}

.mdl-color--light-blue-700 {
  background-color: #0288d1 !important;
}

.mdl-color-text--light-blue-800 {
  color: #0277bd !important;
}

.mdl-color--light-blue-800 {
  background-color: #0277bd !important;
}

.mdl-color-text--light-blue-900 {
  color: #01579b !important;
}

.mdl-color--light-blue-900 {
  background-color: #01579b !important;
}

.mdl-color-text--light-blue-A100 {
  color: #80d8ff !important;
}

.mdl-color--light-blue-A100 {
  background-color: #80d8ff !important;
}

.mdl-color-text--light-blue-A200 {
  color: #40c4ff !important;
}

.mdl-color--light-blue-A200 {
  background-color: #40c4ff !important;
}

.mdl-color-text--light-blue-A400 {
  color: #00b0ff !important;
}

.mdl-color--light-blue-A400 {
  background-color: #00b0ff !important;
}

.mdl-color-text--light-blue-A700 {
  color: #0091ea !important;
}

.mdl-color--light-blue-A700 {
  background-color: #0091ea !important;
}

.mdl-color-text--cyan {
  color: #00bcd4 !important;
}

.mdl-color--cyan {
  background-color: #00bcd4 !important;
}

.mdl-color-text--cyan-50 {
  color: #e0f7fa !important;
}

.mdl-color--cyan-50 {
  background-color: #e0f7fa !important;
}

.mdl-color-text--cyan-100 {
  color: #b2ebf2 !important;
}

.mdl-color--cyan-100 {
  background-color: #b2ebf2 !important;
}

.mdl-color-text--cyan-200 {
  color: #80deea !important;
}

.mdl-color--cyan-200 {
  background-color: #80deea !important;
}

.mdl-color-text--cyan-300 {
  color: #4dd0e1 !important;
}

.mdl-color--cyan-300 {
  background-color: #4dd0e1 !important;
}

.mdl-color-text--cyan-400 {
  color: #26c6da !important;
}

.mdl-color--cyan-400 {
  background-color: #26c6da !important;
}

.mdl-color-text--cyan-500 {
  color: #00bcd4 !important;
}

.mdl-color--cyan-500 {
  background-color: #00bcd4 !important;
}

.mdl-color-text--cyan-600 {
  color: #00acc1 !important;
}

.mdl-color--cyan-600 {
  background-color: #00acc1 !important;
}

.mdl-color-text--cyan-700 {
  color: #0097a7 !important;
}

.mdl-color--cyan-700 {
  background-color: #0097a7 !important;
}

.mdl-color-text--cyan-800 {
  color: #00838f !important;
}

.mdl-color--cyan-800 {
  background-color: #00838f !important;
}

.mdl-color-text--cyan-900 {
  color: #006064 !important;
}

.mdl-color--cyan-900 {
  background-color: #006064 !important;
}

.mdl-color-text--cyan-A100 {
  color: #84ffff !important;
}

.mdl-color--cyan-A100 {
  background-color: #84ffff !important;
}

.mdl-color-text--cyan-A200 {
  color: #18ffff !important;
}

.mdl-color--cyan-A200 {
  background-color: #18ffff !important;
}

.mdl-color-text--cyan-A400 {
  color: #00e5ff !important;
}

.mdl-color--cyan-A400 {
  background-color: #00e5ff !important;
}

.mdl-color-text--cyan-A700 {
  color: #00b8d4 !important;
}

.mdl-color--cyan-A700 {
  background-color: #00b8d4 !important;
}

.mdl-color-text--teal {
  color: #009688 !important;
}

.mdl-color--teal {
  background-color: #009688 !important;
}

.mdl-color-text--teal-50 {
  color: #e0f2f1 !important;
}

.mdl-color--teal-50 {
  background-color: #e0f2f1 !important;
}

.mdl-color-text--teal-100 {
  color: #b2dfdb !important;
}

.mdl-color--teal-100 {
  background-color: #b2dfdb !important;
}

.mdl-color-text--teal-200 {
  color: #80cbc4 !important;
}

.mdl-color--teal-200 {
  background-color: #80cbc4 !important;
}

.mdl-color-text--teal-300 {
  color: #4db6ac !important;
}

.mdl-color--teal-300 {
  background-color: #4db6ac !important;
}

.mdl-color-text--teal-400 {
  color: #26a69a !important;
}

.mdl-color--teal-400 {
  background-color: #26a69a !important;
}

.mdl-color-text--teal-500 {
  color: #009688 !important;
}

.mdl-color--teal-500 {
  background-color: #009688 !important;
}

.mdl-color-text--teal-600 {
  color: #00897b !important;
}

.mdl-color--teal-600 {
  background-color: #00897b !important;
}

.mdl-color-text--teal-700 {
  color: #00796b !important;
}

.mdl-color--teal-700 {
  background-color: #00796b !important;
}

.mdl-color-text--teal-800 {
  color: #00695c !important;
}

.mdl-color--teal-800 {
  background-color: #00695c !important;
}

.mdl-color-text--teal-900 {
  color: #004d40 !important;
}

.mdl-color--teal-900 {
  background-color: #004d40 !important;
}

.mdl-color-text--teal-A100 {
  color: #a7ffeb !important;
}

.mdl-color--teal-A100 {
  background-color: #a7ffeb !important;
}

.mdl-color-text--teal-A200 {
  color: #64ffda !important;
}

.mdl-color--teal-A200 {
  background-color: #64ffda !important;
}

.mdl-color-text--teal-A400 {
  color: #1de9b6 !important;
}

.mdl-color--teal-A400 {
  background-color: #1de9b6 !important;
}

.mdl-color-text--teal-A700 {
  color: #00bfa5 !important;
}

.mdl-color--teal-A700 {
  background-color: #00bfa5 !important;
}

.mdl-color-text--green {
  color: #4caf50 !important;
}

.mdl-color--green {
  background-color: #4caf50 !important;
}

.mdl-color-text--green-50 {
  color: #e8f5e9 !important;
}

.mdl-color--green-50 {
  background-color: #e8f5e9 !important;
}

.mdl-color-text--green-100 {
  color: #c8e6c9 !important;
}

.mdl-color--green-100 {
  background-color: #c8e6c9 !important;
}

.mdl-color-text--green-200 {
  color: #a5d6a7 !important;
}

.mdl-color--green-200 {
  background-color: #a5d6a7 !important;
}

.mdl-color-text--green-300 {
  color: #81c784 !important;
}

.mdl-color--green-300 {
  background-color: #81c784 !important;
}

.mdl-color-text--green-400 {
  color: #66bb6a !important;
}

.mdl-color--green-400 {
  background-color: #66bb6a !important;
}

.mdl-color-text--green-500 {
  color: #4caf50 !important;
}

.mdl-color--green-500 {
  background-color: #4caf50 !important;
}

.mdl-color-text--green-600 {
  color: #43a047 !important;
}

.mdl-color--green-600 {
  background-color: #43a047 !important;
}

.mdl-color-text--green-700 {
  color: #388e3c !important;
}

.mdl-color--green-700 {
  background-color: #388e3c !important;
}

.mdl-color-text--green-800 {
  color: #2e7d32 !important;
}

.mdl-color--green-800 {
  background-color: #2e7d32 !important;
}

.mdl-color-text--green-900 {
  color: #1b5e20 !important;
}

.mdl-color--green-900 {
  background-color: #1b5e20 !important;
}

.mdl-color-text--green-A100 {
  color: #b9f6ca !important;
}

.mdl-color--green-A100 {
  background-color: #b9f6ca !important;
}

.mdl-color-text--green-A200 {
  color: #69f0ae !important;
}

.mdl-color--green-A200 {
  background-color: #69f0ae !important;
}

.mdl-color-text--green-A400 {
  color: #00e676 !important;
}

.mdl-color--green-A400 {
  background-color: #00e676 !important;
}

.mdl-color-text--green-A700 {
  color: #00c853 !important;
}

.mdl-color--green-A700 {
  background-color: #00c853 !important;
}

.mdl-color-text--light-green {
  color: #8bc34a !important;
}

.mdl-color--light-green {
  background-color: #8bc34a !important;
}

.mdl-color-text--light-green-50 {
  color: #f1f8e9 !important;
}

.mdl-color--light-green-50 {
  background-color: #f1f8e9 !important;
}

.mdl-color-text--light-green-100 {
  color: #dcedc8 !important;
}

.mdl-color--light-green-100 {
  background-color: #dcedc8 !important;
}

.mdl-color-text--light-green-200 {
  color: #c5e1a5 !important;
}

.mdl-color--light-green-200 {
  background-color: #c5e1a5 !important;
}

.mdl-color-text--light-green-300 {
  color: #aed581 !important;
}

.mdl-color--light-green-300 {
  background-color: #aed581 !important;
}

.mdl-color-text--light-green-400 {
  color: #9ccc65 !important;
}

.mdl-color--light-green-400 {
  background-color: #9ccc65 !important;
}

.mdl-color-text--light-green-500 {
  color: #8bc34a !important;
}

.mdl-color--light-green-500 {
  background-color: #8bc34a !important;
}

.mdl-color-text--light-green-600 {
  color: #7cb342 !important;
}

.mdl-color--light-green-600 {
  background-color: #7cb342 !important;
}

.mdl-color-text--light-green-700 {
  color: #689f38 !important;
}

.mdl-color--light-green-700 {
  background-color: #689f38 !important;
}

.mdl-color-text--light-green-800 {
  color: #558b2f !important;
}

.mdl-color--light-green-800 {
  background-color: #558b2f !important;
}

.mdl-color-text--light-green-900 {
  color: #33691e !important;
}

.mdl-color--light-green-900 {
  background-color: #33691e !important;
}

.mdl-color-text--light-green-A100 {
  color: #ccff90 !important;
}

.mdl-color--light-green-A100 {
  background-color: #ccff90 !important;
}

.mdl-color-text--light-green-A200 {
  color: #b2ff59 !important;
}

.mdl-color--light-green-A200 {
  background-color: #b2ff59 !important;
}

.mdl-color-text--light-green-A400 {
  color: #76ff03 !important;
}

.mdl-color--light-green-A400 {
  background-color: #76ff03 !important;
}

.mdl-color-text--light-green-A700 {
  color: #64dd17 !important;
}

.mdl-color--light-green-A700 {
  background-color: #64dd17 !important;
}

.mdl-color-text--lime {
  color: #cddc39 !important;
}

.mdl-color--lime {
  background-color: #cddc39 !important;
}

.mdl-color-text--lime-50 {
  color: #f9fbe7 !important;
}

.mdl-color--lime-50 {
  background-color: #f9fbe7 !important;
}

.mdl-color-text--lime-100 {
  color: #f0f4c3 !important;
}

.mdl-color--lime-100 {
  background-color: #f0f4c3 !important;
}

.mdl-color-text--lime-200 {
  color: #e6ee9c !important;
}

.mdl-color--lime-200 {
  background-color: #e6ee9c !important;
}

.mdl-color-text--lime-300 {
  color: #dce775 !important;
}

.mdl-color--lime-300 {
  background-color: #dce775 !important;
}

.mdl-color-text--lime-400 {
  color: #d4e157 !important;
}

.mdl-color--lime-400 {
  background-color: #d4e157 !important;
}

.mdl-color-text--lime-500 {
  color: #cddc39 !important;
}

.mdl-color--lime-500 {
  background-color: #cddc39 !important;
}

.mdl-color-text--lime-600 {
  color: #c0ca33 !important;
}

.mdl-color--lime-600 {
  background-color: #c0ca33 !important;
}

.mdl-color-text--lime-700 {
  color: #afb42b !important;
}

.mdl-color--lime-700 {
  background-color: #afb42b !important;
}

.mdl-color-text--lime-800 {
  color: #9e9d24 !important;
}

.mdl-color--lime-800 {
  background-color: #9e9d24 !important;
}

.mdl-color-text--lime-900 {
  color: #827717 !important;
}

.mdl-color--lime-900 {
  background-color: #827717 !important;
}

.mdl-color-text--lime-A100 {
  color: #f4ff81 !important;
}

.mdl-color--lime-A100 {
  background-color: #f4ff81 !important;
}

.mdl-color-text--lime-A200 {
  color: #eeff41 !important;
}

.mdl-color--lime-A200 {
  background-color: #eeff41 !important;
}

.mdl-color-text--lime-A400 {
  color: #c6ff00 !important;
}

.mdl-color--lime-A400 {
  background-color: #c6ff00 !important;
}

.mdl-color-text--lime-A700 {
  color: #aeea00 !important;
}

.mdl-color--lime-A700 {
  background-color: #aeea00 !important;
}

.mdl-color-text--yellow {
  color: #ffeb3b !important;
}

.mdl-color--yellow {
  background-color: #ffeb3b !important;
}

.mdl-color-text--yellow-50 {
  color: #fffde7 !important;
}

.mdl-color--yellow-50 {
  background-color: #fffde7 !important;
}

.mdl-color-text--yellow-100 {
  color: #fff9c4 !important;
}

.mdl-color--yellow-100 {
  background-color: #fff9c4 !important;
}

.mdl-color-text--yellow-200 {
  color: #fff59d !important;
}

.mdl-color--yellow-200 {
  background-color: #fff59d !important;
}

.mdl-color-text--yellow-300 {
  color: #fff176 !important;
}

.mdl-color--yellow-300 {
  background-color: #fff176 !important;
}

.mdl-color-text--yellow-400 {
  color: #ffee58 !important;
}

.mdl-color--yellow-400 {
  background-color: #ffee58 !important;
}

.mdl-color-text--yellow-500 {
  color: #ffeb3b !important;
}

.mdl-color--yellow-500 {
  background-color: #ffeb3b !important;
}

.mdl-color-text--yellow-600 {
  color: #fdd835 !important;
}

.mdl-color--yellow-600 {
  background-color: #fdd835 !important;
}

.mdl-color-text--yellow-700 {
  color: #fbc02d !important;
}

.mdl-color--yellow-700 {
  background-color: #fbc02d !important;
}

.mdl-color-text--yellow-800 {
  color: #f9a825 !important;
}

.mdl-color--yellow-800 {
  background-color: #f9a825 !important;
}

.mdl-color-text--yellow-900 {
  color: #f57f17 !important;
}

.mdl-color--yellow-900 {
  background-color: #f57f17 !important;
}

.mdl-color-text--yellow-A100 {
  color: #ffff8d !important;
}

.mdl-color--yellow-A100 {
  background-color: #ffff8d !important;
}

.mdl-color-text--yellow-A200 {
  color: #ff0 !important;
}

.mdl-color--yellow-A200 {
  background-color: #ff0 !important;
}

.mdl-color-text--yellow-A400 {
  color: #ffea00 !important;
}

.mdl-color--yellow-A400 {
  background-color: #ffea00 !important;
}

.mdl-color-text--yellow-A700 {
  color: #ffd600 !important;
}

.mdl-color--yellow-A700 {
  background-color: #ffd600 !important;
}

.mdl-color-text--amber {
  color: #ffc107 !important;
}

.mdl-color--amber {
  background-color: #ffc107 !important;
}

.mdl-color-text--amber-50 {
  color: #fff8e1 !important;
}

.mdl-color--amber-50 {
  background-color: #fff8e1 !important;
}

.mdl-color-text--amber-100 {
  color: #ffecb3 !important;
}

.mdl-color--amber-100 {
  background-color: #ffecb3 !important;
}

.mdl-color-text--amber-200 {
  color: #ffe082 !important;
}

.mdl-color--amber-200 {
  background-color: #ffe082 !important;
}

.mdl-color-text--amber-300 {
  color: #ffd54f !important;
}

.mdl-color--amber-300 {
  background-color: #ffd54f !important;
}

.mdl-color-text--amber-400 {
  color: #ffca28 !important;
}

.mdl-color--amber-400 {
  background-color: #ffca28 !important;
}

.mdl-color-text--amber-500 {
  color: #ffc107 !important;
}

.mdl-color--amber-500 {
  background-color: #ffc107 !important;
}

.mdl-color-text--amber-600 {
  color: #ffb300 !important;
}

.mdl-color--amber-600 {
  background-color: #ffb300 !important;
}

.mdl-color-text--amber-700 {
  color: #ffa000 !important;
}

.mdl-color--amber-700 {
  background-color: #ffa000 !important;
}

.mdl-color-text--amber-800 {
  color: #ff8f00 !important;
}

.mdl-color--amber-800 {
  background-color: #ff8f00 !important;
}

.mdl-color-text--amber-900 {
  color: #ff6f00 !important;
}

.mdl-color--amber-900 {
  background-color: #ff6f00 !important;
}

.mdl-color-text--amber-A100 {
  color: #ffe57f !important;
}

.mdl-color--amber-A100 {
  background-color: #ffe57f !important;
}

.mdl-color-text--amber-A200 {
  color: #ffd740 !important;
}

.mdl-color--amber-A200 {
  background-color: #ffd740 !important;
}

.mdl-color-text--amber-A400 {
  color: #ffc400 !important;
}

.mdl-color--amber-A400 {
  background-color: #ffc400 !important;
}

.mdl-color-text--amber-A700 {
  color: #ffab00 !important;
}

.mdl-color--amber-A700 {
  background-color: #ffab00 !important;
}

.mdl-color-text--orange {
  color: #ff9800 !important;
}

.mdl-color--orange {
  background-color: #ff9800 !important;
}

.mdl-color-text--orange-50 {
  color: #fff3e0 !important;
}

.mdl-color--orange-50 {
  background-color: #fff3e0 !important;
}

.mdl-color-text--orange-100 {
  color: #ffe0b2 !important;
}

.mdl-color--orange-100 {
  background-color: #ffe0b2 !important;
}

.mdl-color-text--orange-200 {
  color: #ffcc80 !important;
}

.mdl-color--orange-200 {
  background-color: #ffcc80 !important;
}

.mdl-color-text--orange-300 {
  color: #ffb74d !important;
}

.mdl-color--orange-300 {
  background-color: #ffb74d !important;
}

.mdl-color-text--orange-400 {
  color: #ffa726 !important;
}

.mdl-color--orange-400 {
  background-color: #ffa726 !important;
}

.mdl-color-text--orange-500 {
  color: #ff9800 !important;
}

.mdl-color--orange-500 {
  background-color: #ff9800 !important;
}

.mdl-color-text--orange-600 {
  color: #fb8c00 !important;
}

.mdl-color--orange-600 {
  background-color: #fb8c00 !important;
}

.mdl-color-text--orange-700 {
  color: #f57c00 !important;
}

.mdl-color--orange-700 {
  background-color: #f57c00 !important;
}

.mdl-color-text--orange-800 {
  color: #ef6c00 !important;
}

.mdl-color--orange-800 {
  background-color: #ef6c00 !important;
}

.mdl-color-text--orange-900 {
  color: #e65100 !important;
}

.mdl-color--orange-900 {
  background-color: #e65100 !important;
}

.mdl-color-text--orange-A100 {
  color: #ffd180 !important;
}

.mdl-color--orange-A100 {
  background-color: #ffd180 !important;
}

.mdl-color-text--orange-A200 {
  color: #ffab40 !important;
}

.mdl-color--orange-A200 {
  background-color: #ffab40 !important;
}

.mdl-color-text--orange-A400 {
  color: #ff9100 !important;
}

.mdl-color--orange-A400 {
  background-color: #ff9100 !important;
}

.mdl-color-text--orange-A700 {
  color: #ff6d00 !important;
}

.mdl-color--orange-A700 {
  background-color: #ff6d00 !important;
}

.mdl-color-text--deep-orange {
  color: #ff5722 !important;
}

.mdl-color--deep-orange {
  background-color: #ff5722 !important;
}

.mdl-color-text--deep-orange-50 {
  color: #fbe9e7 !important;
}

.mdl-color--deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.mdl-color-text--deep-orange-100 {
  color: #ffccbc !important;
}

.mdl-color--deep-orange-100 {
  background-color: #ffccbc !important;
}

.mdl-color-text--deep-orange-200 {
  color: #ffab91 !important;
}

.mdl-color--deep-orange-200 {
  background-color: #ffab91 !important;
}

.mdl-color-text--deep-orange-300 {
  color: #ff8a65 !important;
}

.mdl-color--deep-orange-300 {
  background-color: #ff8a65 !important;
}

.mdl-color-text--deep-orange-400 {
  color: #ff7043 !important;
}

.mdl-color--deep-orange-400 {
  background-color: #ff7043 !important;
}

.mdl-color-text--deep-orange-500 {
  color: #ff5722 !important;
}

.mdl-color--deep-orange-500 {
  background-color: #ff5722 !important;
}

.mdl-color-text--deep-orange-600 {
  color: #f4511e !important;
}

.mdl-color--deep-orange-600 {
  background-color: #f4511e !important;
}

.mdl-color-text--deep-orange-700 {
  color: #e64a19 !important;
}

.mdl-color--deep-orange-700 {
  background-color: #e64a19 !important;
}

.mdl-color-text--deep-orange-800 {
  color: #d84315 !important;
}

.mdl-color--deep-orange-800 {
  background-color: #d84315 !important;
}

.mdl-color-text--deep-orange-900 {
  color: #bf360c !important;
}

.mdl-color--deep-orange-900 {
  background-color: #bf360c !important;
}

.mdl-color-text--deep-orange-A100 {
  color: #ff9e80 !important;
}

.mdl-color--deep-orange-A100 {
  background-color: #ff9e80 !important;
}

.mdl-color-text--deep-orange-A200 {
  color: #ff6e40 !important;
}

.mdl-color--deep-orange-A200 {
  background-color: #ff6e40 !important;
}

.mdl-color-text--deep-orange-A400 {
  color: #ff3d00 !important;
}

.mdl-color--deep-orange-A400 {
  background-color: #ff3d00 !important;
}

.mdl-color-text--deep-orange-A700 {
  color: #dd2c00 !important;
}

.mdl-color--deep-orange-A700 {
  background-color: #dd2c00 !important;
}

.mdl-color-text--brown {
  color: #795548 !important;
}

.mdl-color--brown {
  background-color: #795548 !important;
}

.mdl-color-text--brown-50 {
  color: #efebe9 !important;
}

.mdl-color--brown-50 {
  background-color: #efebe9 !important;
}

.mdl-color-text--brown-100 {
  color: #d7ccc8 !important;
}

.mdl-color--brown-100 {
  background-color: #d7ccc8 !important;
}

.mdl-color-text--brown-200 {
  color: #bcaaa4 !important;
}

.mdl-color--brown-200 {
  background-color: #bcaaa4 !important;
}

.mdl-color-text--brown-300 {
  color: #a1887f !important;
}

.mdl-color--brown-300 {
  background-color: #a1887f !important;
}

.mdl-color-text--brown-400 {
  color: #8d6e63 !important;
}

.mdl-color--brown-400 {
  background-color: #8d6e63 !important;
}

.mdl-color-text--brown-500 {
  color: #795548 !important;
}

.mdl-color--brown-500 {
  background-color: #795548 !important;
}

.mdl-color-text--brown-600 {
  color: #6d4c41 !important;
}

.mdl-color--brown-600 {
  background-color: #6d4c41 !important;
}

.mdl-color-text--brown-700 {
  color: #5d4037 !important;
}

.mdl-color--brown-700 {
  background-color: #5d4037 !important;
}

.mdl-color-text--brown-800 {
  color: #4e342e !important;
}

.mdl-color--brown-800 {
  background-color: #4e342e !important;
}

.mdl-color-text--brown-900 {
  color: #3e2723 !important;
}

.mdl-color--brown-900 {
  background-color: #3e2723 !important;
}

.mdl-color-text--grey {
  color: #9e9e9e !important;
}

.mdl-color--grey {
  background-color: #9e9e9e !important;
}

.mdl-color-text--grey-50 {
  color: #fafafa !important;
}

.mdl-color--grey-50 {
  background-color: #fafafa !important;
}

.mdl-color-text--grey-100 {
  color: #f5f5f5 !important;
}

.mdl-color--grey-100 {
  background-color: #f5f5f5 !important;
}

.mdl-color-text--grey-200 {
  color: #eee !important;
}

.mdl-color--grey-200 {
  background-color: #eee !important;
}

.mdl-color-text--grey-300 {
  color: #e0e0e0 !important;
}

.mdl-color--grey-300 {
  background-color: #e0e0e0 !important;
}

.mdl-color-text--grey-400 {
  color: #bdbdbd !important;
}

.mdl-color--grey-400 {
  background-color: #bdbdbd !important;
}

.mdl-color-text--grey-500 {
  color: #9e9e9e !important;
}

.mdl-color--grey-500 {
  background-color: #9e9e9e !important;
}

.mdl-color-text--grey-600 {
  color: #757575 !important;
}

.mdl-color--grey-600 {
  background-color: #757575 !important;
}

.mdl-color-text--grey-700 {
  color: #616161 !important;
}

.mdl-color--grey-700 {
  background-color: #616161 !important;
}

.mdl-color-text--grey-800 {
  color: #424242 !important;
}

.mdl-color--grey-800 {
  background-color: #424242 !important;
}

.mdl-color-text--grey-900 {
  color: #212121 !important;
}

.mdl-color--grey-900 {
  background-color: #212121 !important;
}

.mdl-color-text--blue-grey {
  color: #607d8b !important;
}

.mdl-color--blue-grey {
  background-color: #607d8b !important;
}

.mdl-color-text--blue-grey-50 {
  color: #eceff1 !important;
}

.mdl-color--blue-grey-50 {
  background-color: #eceff1 !important;
}

.mdl-color-text--blue-grey-100 {
  color: #cfd8dc !important;
}

.mdl-color--blue-grey-100 {
  background-color: #cfd8dc !important;
}

.mdl-color-text--blue-grey-200 {
  color: #b0bec5 !important;
}

.mdl-color--blue-grey-200 {
  background-color: #b0bec5 !important;
}

.mdl-color-text--blue-grey-300 {
  color: #90a4ae !important;
}

.mdl-color--blue-grey-300 {
  background-color: #90a4ae !important;
}

.mdl-color-text--blue-grey-400 {
  color: #78909c !important;
}

.mdl-color--blue-grey-400 {
  background-color: #78909c !important;
}

.mdl-color-text--blue-grey-500 {
  color: #607d8b !important;
}

.mdl-color--blue-grey-500 {
  background-color: #607d8b !important;
}

.mdl-color-text--blue-grey-600 {
  color: #546e7a !important;
}

.mdl-color--blue-grey-600 {
  background-color: #546e7a !important;
}

.mdl-color-text--blue-grey-700 {
  color: #455a64 !important;
}

.mdl-color--blue-grey-700 {
  background-color: #455a64 !important;
}

.mdl-color-text--blue-grey-800 {
  color: #37474f !important;
}

.mdl-color--blue-grey-800 {
  background-color: #37474f !important;
}

.mdl-color-text--blue-grey-900 {
  color: #263238 !important;
}

.mdl-color--blue-grey-900 {
  background-color: #263238 !important;
}

.mdl-color--black {
  background-color: #000 !important;
}

.mdl-color-text--black {
  color: #000 !important;
}

.mdl-color--white {
  background-color: #fff !important;
}

.mdl-color-text--white {
  color: #fff !important;
}

.mdl-color--primary {
  background-color: #3f51b5 !important;
}

.mdl-color--primary-contrast {
  background-color: #fff !important;
}

.mdl-color--primary-dark {
  background-color: #303f9f !important;
}

.mdl-color--accent {
  background-color: #2f2f2f !important;
}

.mdl-color--accent-contrast {
  background-color: #fff !important;
}

.mdl-color-text--primary {
  color: #3f51b5 !important;
}

.mdl-color-text--primary-contrast {
  color: #fff !important;
}

.mdl-color-text--primary-dark {
  color: #303f9f !important;
}

.mdl-color-text--accent {
  color: #2f2f2f !important;
}

.mdl-color-text--accent-contrast {
  color: #fff !important;
}

.mdl-ripple {
  background: #000;
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden;
}

.mdl-ripple.is-animating {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
}

.mdl-ripple.is-visible {
  opacity: .3;
}

.mdl-animation--default, .mdl-animation--fast-out-slow-in {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-animation--linear-out-slow-in {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.mdl-animation--fast-out-linear-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}

.mdl-badge:not([data-badge]) {
  margin-right: auto;
}

.mdl-badge[data-badge]:after {
  content: attr(data-badge);
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -moz-box-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -moz-box-align: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #2f2f2f;
  color: #fff;
}

.mdl-button .mdl-badge[data-badge]:after {
  top: -10px;
  right: -5px;
}

.mdl-badge.mdl-badge--no-background[data-badge]:after {
  color: #2f2f2f;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 1px gray;
}

.mdl-badge.mdl-badge--overlap {
  margin-right: 10px;
}

.mdl-badge.mdl-badge--overlap:after {
  right: -10px;
}

.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.mdl-button::-moz-focus-inner {
  border: 0;
}

.mdl-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.mdl-button:active {
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button.mdl-button--colored {
  color: #3f51b5;
}

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0, 0, 0, 0.12);
}

input.mdl-button[type="submit"] {
  -webkit-appearance: none;
}

.mdl-button--raised {
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-button--raised:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--raised.mdl-button--colored {
  background: #3f51b5;
  color: #fff;
}

.mdl-button--raised.mdl-button--colored:hover {
  background-color: #3f51b5;
}

.mdl-button--raised.mdl-button--colored:active {
  background-color: #3f51b5;
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}

.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}

.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}

.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button--fab:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--fab:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--fab.mdl-button--colored {
  background: #2f2f2f;
  color: #fff;
}

.mdl-button--fab.mdl-button--colored:hover {
  background-color: #2f2f2f;
}

.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  background-color: #2f2f2f;
}

.mdl-button--fab.mdl-button--colored:active {
  background-color: #2f2f2f;
}

.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
}

.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0;
  left: 0;
}

.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}

.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple, .mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent;
}

.mdl-button--primary.mdl-button--primary {
  color: #3f51b5;
}

.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: #fff;
}

.mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
  color: #fff;
  background-color: #3f51b5;
}

.mdl-button--accent.mdl-button--accent {
  color: #2f2f2f;
}

.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: #fff;
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
  color: #fff;
  background-color: #2f2f2f;
}

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  background-color: transparent;
}

.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
}

.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-button .material-icons {
  vertical-align: middle;
}

.mdl-card {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 101; /* musi być większe od z-index z klasy .popup-background-blocker */
  position: relative;
  background: #fff;
  border-radius: 2px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-card__media {
  background-color: #2f2f2f;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-card__title {
  -ms-flex-align: center;
  -moz-box-align: center;
  align-items: center;
  color: #000;
  display: block;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-pack: stretch;
  -moz-box-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  padding: 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__title-text {
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: inherit;
  display: block;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0;
}

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}

.mdl-card__supporting-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px;
  width: 90%;
}

.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card--expand {
  -ms-flex-positive: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

.mdl-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
}

.mdl-checkbox.is-upgraded {
  padding-left: 24px;
}

.mdl-checkbox__input {
  line-height: 24px;
}

.mdl-checkbox.is-upgraded .mdl-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-checkbox__box-outline {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  z-index: 2;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
  border: 2px solid #3f51b5;
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__box-outline, .mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
  border: 2px solid rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.mdl-checkbox__focus-helper {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
}

.mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

.mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.26);
  background-color: rgba(63, 81, 181, 0.26);
}

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==");
  mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==");
  background: 0 0;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: #3f51b5 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
}

fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline, .mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
  background: rgba(0, 0, 0, 0.26) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
}

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__label, .mdl-checkbox.is-disabled .mdl-checkbox__label {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -10px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-checkbox__ripple-container .mdl-ripple {
  background: #3f51b5;
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container, .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
  cursor: auto;
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container .mdl-ripple, .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
  background: 0 0;
}

.mdl-chip {
  height: 32px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 32px;
  padding: 0 12px;
  border: 0;
  border-radius: 16px;
  background-color: #f9f9f9;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  margin: 2px 0;
  font-size: 0;
  white-space: nowrap;
}

.mdl-chip__text {
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
}

.mdl-chip__action {
  height: 24px;
  width: 24px;
  background: 0 0;
  opacity: .54;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 4px;
  font-size: 13px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: none;
}

.mdl-chip__action, .mdl-chip__contact {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-align: center;
}

.mdl-chip__contact {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-right: 8px;
  font-size: 18px;
  line-height: 32px;
}

.mdl-chip:focus {
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-chip:active {
  background-color: #d6d6d6;
}

.mdl-chip--deletable {
  padding-right: 4px;
}

.mdl-chip--contact {
  padding-left: 0;
}

.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: #fff;
}

.mdl-data-table thead {
  padding-bottom: 3px;
}

.mdl-data-table thead .mdl-data-table__select {
  margin-top: 0;
}

.mdl-data-table tbody tr {
  position: relative;
  height: 48px;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color;
}

.mdl-data-table tbody tr.is-selected {
  background-color: #e0e0e0;
}

.mdl-data-table tbody tr:hover {
  background-color: #eee;
}

.mdl-data-table td {
  text-align: right;
}

.mdl-data-table th {
  padding: 0 18px 12px 18px;
  text-align: right;
}

.mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
  padding-left: 24px;
}

.mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
  padding-right: 24px;
}

.mdl-data-table td {
  position: relative;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 18px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-data-table td, .mdl-data-table td .mdl-data-table__select {
  vertical-align: middle;
}

.mdl-data-table th {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending, .mdl-data-table th.mdl-data-table__header--sorted-descending {
  color: rgba(0, 0, 0, 0.87);
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  content: "\e5d8";
  margin-right: 5px;
  vertical-align: sub;
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover {
  cursor: pointer;
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  content: "\e5db";
}

.mdl-data-table__select {
  width: 16px;
}

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left;
}

.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px;
}

.mdl-dialog__title {
  padding: 24px 24px 0;
  margin: 0;
  font-size: 2.5rem;
}

.mdl-dialog__actions {
  padding: 8px 8px 8px 24px;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row-reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  flex-direction: row-reverse;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mdl-dialog__actions > * {
  margin-right: 8px;
  height: 36px;
}

.mdl-dialog__actions > *:first-child {
  margin-right: 0;
}

.mdl-dialog__actions--full-width {
  padding: 0 0 8px;
}

.mdl-dialog__actions--full-width > * {
  height: 48px;
  -ms-flex: 0 0 100%;
  -moz-box-flex: 0;
  flex: 0 0 100%;
  padding-right: 16px;
  margin-right: 0;
  text-align: right;
}

.mdl-dialog__content {
  padding: 20px 24px 24px;
  color: rgba(0, 0, 0, 0.54);
}

.mdl-mega-footer {
  padding: 16px 40px;
  color: #9e9e9e;
  background-color: #424242;
}

.mdl-mega-footer--top-section:after, .mdl-mega-footer--middle-section:after, .mdl-mega-footer--bottom-section:after, .mdl-mega-footer__top-section:after, .mdl-mega-footer__middle-section:after, .mdl-mega-footer__bottom-section:after {
  content: '';
  display: block;
  clear: both;
}

.mdl-mega-footer--left-section, .mdl-mega-footer__left-section, .mdl-mega-footer--right-section, .mdl-mega-footer__right-section {
  margin-bottom: 16px;
}

.mdl-mega-footer--right-section a, .mdl-mega-footer__right-section a {
  display: block;
  margin-bottom: 16px;
  color: inherit;
  text-decoration: none;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--left-section, .mdl-mega-footer__left-section {
    float: left;
  }

  .mdl-mega-footer--right-section, .mdl-mega-footer__right-section {
    float: right;
  }

  .mdl-mega-footer--right-section a, .mdl-mega-footer__right-section a {
    display: inline-block;
    margin-left: 16px;
    line-height: 36px;
    vertical-align: middle;
  }
}

.mdl-mega-footer--social-btn, .mdl-mega-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: #9e9e9e;
  border: none;
}

.mdl-mega-footer--drop-down-section, .mdl-mega-footer__drop-down-section {
  display: block;
  position: relative;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--drop-down-section, .mdl-mega-footer__drop-down-section {
    width: 33%;
  }

  .mdl-mega-footer--drop-down-section:nth-child(1), .mdl-mega-footer--drop-down-section:nth-child(2), .mdl-mega-footer__drop-down-section:nth-child(1), .mdl-mega-footer__drop-down-section:nth-child(2) {
    float: left;
  }

  .mdl-mega-footer--drop-down-section:nth-child(3), .mdl-mega-footer__drop-down-section:nth-child(3) {
    float: right;
  }

  .mdl-mega-footer--drop-down-section:nth-child(3):after, .mdl-mega-footer__drop-down-section:nth-child(3):after {
    clear: right;
  }

  .mdl-mega-footer--drop-down-section:nth-child(4), .mdl-mega-footer__drop-down-section:nth-child(4) {
    clear: right;
    float: right;
  }

  .mdl-mega-footer--middle-section:after, .mdl-mega-footer__middle-section:after {
    content: '';
    display: block;
    clear: both;
  }

  .mdl-mega-footer--bottom-section, .mdl-mega-footer__bottom-section {
    padding-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .mdl-mega-footer--drop-down-section, .mdl-mega-footer--drop-down-section:nth-child(3), .mdl-mega-footer--drop-down-section:nth-child(4), .mdl-mega-footer__drop-down-section, .mdl-mega-footer__drop-down-section:nth-child(3), .mdl-mega-footer__drop-down-section:nth-child(4) {
    width: 24%;
    float: left;
  }
}

.mdl-mega-footer--heading-checkbox, .mdl-mega-footer__heading-checkbox {
  position: absolute;
  width: 100%;
  height: 55.8px;
  padding: 32px;
  margin: -16px 0 0;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}

.mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after, .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after, .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CE';
}

.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list, .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list, .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list, .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list {
  display: none;
}

.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CF';
}

.mdl-mega-footer--heading, .mdl-mega-footer__heading {
  position: relative;
  width: 100%;
  padding-right: 39.8px;
  margin-bottom: 16px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #e0e0e0;
}

.mdl-mega-footer--heading:after, .mdl-mega-footer__heading:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23.8px;
  height: 23.8px;
  background-size: cover;
}

.mdl-mega-footer--link-list, .mdl-mega-footer__link-list {
  list-style: none;
  padding: 0;
  margin: 0 0 32px;
}

.mdl-mega-footer--link-list:after, .mdl-mega-footer__link-list:after {
  clear: both;
  display: block;
  content: '';
}

.mdl-mega-footer--link-list li, .mdl-mega-footer__link-list li {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}

.mdl-mega-footer--link-list a, .mdl-mega-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--heading-checkbox, .mdl-mega-footer__heading-checkbox {
    display: none;
  }

  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after, .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after, .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
    content: '';
  }

  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list, .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list, .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list, .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list {
    display: block;
  }

  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after, .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
    content: '';
  }
}

.mdl-mega-footer--bottom-section, .mdl-mega-footer__bottom-section {
  padding-top: 16px;
  margin-bottom: 16px;
}

.mdl-logo {
  margin-bottom: 16px;
  color: #fff;
}

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li, .mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
  float: left;
  margin-bottom: 0;
  margin-right: 16px;
}

@media screen and (min-width: 760px) {
  .mdl-logo {
    float: left;
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.mdl-mini-footer {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-flow: row wrap;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding: 32px 16px;
  color: #9e9e9e;
  background-color: #424242;
}

.mdl-mini-footer:after {
  content: '';
  display: block;
}

.mdl-mini-footer .mdl-logo {
  line-height: 36px;
}

.mdl-mini-footer--link-list, .mdl-mini-footer__link-list {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-flow: row nowrap;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mdl-mini-footer--link-list li, .mdl-mini-footer__link-list li {
  margin-bottom: 0;
  margin-right: 16px;
}

@media screen and (min-width: 760px) {
  .mdl-mini-footer--link-list li, .mdl-mini-footer__link-list li {
    line-height: 36px;
  }
}

.mdl-mini-footer--link-list a, .mdl-mini-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

.mdl-mini-footer--left-section, .mdl-mini-footer__left-section {
  display: inline-block;
  -ms-flex-order: 0;
  -moz-box-ordinal-group: 1;
  order: 0;
}

.mdl-mini-footer--right-section, .mdl-mini-footer__right-section {
  display: inline-block;
  -ms-flex-order: 1;
  -moz-box-ordinal-group: 2;
  order: 1;
}

.mdl-mini-footer--social-btn, .mdl-mini-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: #9e9e9e;
  border: none;
}

.mdl-icon-toggle {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0;
}

.mdl-icon-toggle__input {
  line-height: 32px;
}

.mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-icon-toggle__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: #616161;
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: transparent;
  will-change: background-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-icon-toggle__label.material-icons {
  line-height: 32px;
  font-size: 24px;
}

.mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
  color: #3f51b5;
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
  transition: none;
}

.mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
  background-color: rgba(63, 81, 181, 0.26);
}

.mdl-icon-toggle__ripple-container {
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -2px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-icon-toggle__ripple-container .mdl-ripple {
  background: #616161;
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
  cursor: auto;
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
  background: 0 0;
}

.mdl-list {
  display: block;
  padding: 8px 0;
  list-style: none;
}

.mdl-list__item {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  min-height: 48px;
  -ms-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 16px;
  cursor: default;
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden;
}

.mdl-list__item, .mdl-list__item .mdl-list__item-primary-content {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-align: center;
  -moz-box-align: center;
  align-items: center;
}

.mdl-list__item .mdl-list__item-primary-content {
  -ms-flex-order: 0;
  -moz-box-ordinal-group: 1;
  order: 0;
  -ms-flex-positive: 2;
  -moz-box-flex: 2;
  flex-grow: 2;
  text-decoration: none;
}

.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-icon {
  margin-right: 32px;
}

.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-avatar {
  margin-right: 16px;
}

.mdl-list__item .mdl-list__item-secondary-content {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-flow: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-flow: column;
  -ms-flex-align: end;
  -moz-box-align: end;
  align-items: flex-end;
  margin-left: 16px;
}

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-action label {
  display: inline;
}

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.54);
}

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-sub-header {
  padding: 0 0 0 16px;
}

.mdl-list__item-icon, .mdl-list__item-icon.material-icons {
  height: 24px;
  width: 24px;
  font-size: 24px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #757575;
}

.mdl-list__item-avatar, .mdl-list__item-avatar.material-icons {
  height: 40px;
  width: 40px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #757575;
  font-size: 40px;
  color: #fff;
}

.mdl-list__item--two-line {
  height: 72px;
}

.mdl-list__item--two-line .mdl-list__item-primary-content {
  height: 36px;
  line-height: 20px;
  display: block;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-avatar {
  float: left;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-icon {
  float: left;
  margin-top: 6px;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-secondary-content {
  height: 36px;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  padding: 0;
}

.mdl-list__item--three-line {
  height: 88px;
}

.mdl-list__item--three-line .mdl-list__item-primary-content {
  height: 52px;
  line-height: 20px;
  display: block;
}

.mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-avatar, .mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-icon {
  float: left;
}

.mdl-list__item--three-line .mdl-list__item-secondary-content {
  height: 52px;
}

.mdl-list__item--three-line .mdl-list__item-text-body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  height: 52px;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  padding: 0;
}

.mdl-menu__container {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  overflow: visible;
  height: 0;
  width: 0;
  visibility: hidden;
  z-index: -1;
}

.mdl-menu__container.is-visible, .mdl-menu__container.is-animating {
  z-index: 999;
  visibility: visible;
}

.mdl-menu__outline {
  display: block;
  background: #fff;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}

.mdl-menu__container.is-visible .mdl-menu__outline {
  opacity: 1;
  transform: scale(1);
  z-index: 999;
}

.mdl-menu__outline.mdl-menu--bottom-right {
  transform-origin: 100% 0;
}

.mdl-menu__outline.mdl-menu--top-left {
  transform-origin: 0 100%;
}

.mdl-menu__outline.mdl-menu--top-right {
  transform-origin: 100% 100%;
}

.mdl-menu {
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 124px;
  padding: 8px 0;
  margin: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1;
}

.mdl-menu__container.is-visible .mdl-menu {
  opacity: 1;
  z-index: 999;
}

.mdl-menu.is-animating {
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-menu.mdl-menu--bottom-right {
  left: auto;
  right: 0;
}

.mdl-menu.mdl-menu--top-left {
  top: auto;
  bottom: 0;
}

.mdl-menu.mdl-menu--top-right {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

.mdl-menu.mdl-menu--unaligned {
  top: auto;
  left: auto;
}

.mdl-menu__item {
  display: block;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: #bdbdbd;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mdl-menu__container.is-visible .mdl-menu__item {
  opacity: 1;
}

.mdl-menu__item::-moz-focus-inner {
  border: 0;
}

.mdl-menu__item--full-bleed-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdl-menu__item[disabled], .mdl-menu__item[data-mdl-disabled] {
  color: #bdbdbd;
  background-color: transparent;
  cursor: auto;
}

.mdl-menu__item[disabled]:hover, .mdl-menu__item[data-mdl-disabled]:hover {
  background-color: transparent;
}

.mdl-menu__item[disabled]:focus, .mdl-menu__item[data-mdl-disabled]:focus {
  background-color: transparent;
}

.mdl-menu__item[disabled] .mdl-ripple, .mdl-menu__item[data-mdl-disabled] .mdl-ripple {
  background: 0 0;
}

.mdl-menu__item:hover {
  background-color: #eee;
}

.mdl-menu__item:focus {
  outline: none;
  background-color: #eee;
}

.mdl-menu__item:active {
  background-color: #e0e0e0;
}

.mdl-menu__item--ripple-container {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}

.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
  max-width: 100%;
}

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-progress > .progressbar {
  background-color: #3f51b5;
  z-index: 1;
  left: 0;
}

.mdl-progress > .bufferbar {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(to right, #3f51b5, #3f51b5);
  z-index: 0;
  left: 0;
}

.mdl-progress > .auxbar {
  right: 0;
}

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar, .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(to right, #3f51b5, #3f51b5);
    -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=");
    mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=");
  }
}

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar, .mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(to right, #3f51b5, #3f51b5);
}

.mdl-progress.mdl-progress--indeterminate > .bar1, .mdl-progress.mdl-progress__indeterminate > .bar1 {
  animation-name: indeterminate1;
}

.mdl-progress.mdl-progress--indeterminate > .bar1, .mdl-progress.mdl-progress__indeterminate > .bar1, .mdl-progress.mdl-progress--indeterminate > .bar3, .mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-color: #3f51b5;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mdl-progress.mdl-progress--indeterminate > .bar3, .mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  animation-name: indeterminate2;
}

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}

@keyframes indeterminate2 {
  0%, 50% {
    left: 0%;
    width: 0%;
  }
  75% {
    left: 0%;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

.mdl-navigation {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-navigation__link {
  color: #424242;
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: .87;
}

.mdl-navigation__link .material-icons {
  vertical-align: middle;
}

.mdl-layout {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none;
}

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none;
}

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdl-layout__title, .mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  letter-spacing: .02em;
  font-weight: 400;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-layout-spacer {
  -ms-flex-positive: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
}

.mdl-layout__drawer {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 1px solid #e0e0e0;
  background: #fafafa;
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: #424242;
  overflow: visible;
  overflow-y: auto;
  z-index: 5;
}

.mdl-layout__drawer.is-visible {
  transform: translateX(0);
}

.mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
  overflow: hidden;
}

.mdl-layout__drawer > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.mdl-layout__drawer > .mdl-layout__title, .mdl-layout__drawer > .mdl-layout-title {
  line-height: 64px;
  padding-left: 40px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer > .mdl-layout__title, .mdl-layout__drawer > .mdl-layout-title {
    line-height: 56px;
    padding-left: 16px;
  }
}

.mdl-layout__drawer .mdl-navigation {
  -ms-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -ms-flex-align: stretch;
  -moz-box-align: stretch;
  align-items: stretch;
  padding-top: 16px;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 16px;
  }
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: #e0e0e0;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
  background-color: #e0e0e0;
  color: #000;
}

@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    transform: translateX(0);
  }
}

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 4;
}

.mdl-layout__header .mdl-layout__drawer-button {
  position: absolute;
  color: #fff;
  background-color: inherit;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header .mdl-layout__drawer-button {
    margin: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer-button {
    margin: 4px;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (min-width: 1025px) {
  .mdl-layout__drawer-button {
    line-height: 54px;
  }

  .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button, .mdl-layout--fixed-drawer > .mdl-layout__drawer-button, .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
    display: none;
  }
}

.mdl-layout__header {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -moz-box-pack: start;
  justify-content: flex-start;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: #3f51b5;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    min-height: 56px;
  }
}

.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
  margin-left: 240px;
  width: calc(100% - 240px);
}

@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
    padding-left: 40px;
  }
}

.mdl-layout__header > .mdl-layout-icon {
  position: absolute;
  left: 40px;
  top: 16px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  z-index: 3;
  display: block;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header > .mdl-layout-icon {
    left: 16px;
    top: 12px;
  }
}

.mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
  display: none;
}

.mdl-layout__header.is-compact {
  max-height: 64px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact {
    max-height: 56px;
  }
}

.mdl-layout__header.is-compact.has-tabs {
  height: 112px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact.has-tabs {
    min-height: 104px;
  }
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    display: none;
  }

  .mdl-layout--fixed-header > .mdl-layout__header {
    display: -ms-flexbox;
    display: -moz-box;
    display: flex;
  }
}

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none;
}

.mdl-layout__header--seamed, .mdl-layout__header--scroll {
  box-shadow: none;
}

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden;
}

.mdl-layout__header--waterfall.is-casting-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
  -ms-flex-pack: end;
  -moz-box-pack: end;
  justify-content: flex-end;
}

.mdl-layout__header-row {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -ms-flex-align: center;
  -moz-box-align: center;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px;
}

.mdl-layout--no-drawer-button .mdl-layout__header-row {
  padding-left: 40px;
}

@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
    padding-left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row {
    height: 56px;
    padding: 0 16px 0 72px;
  }

  .mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 16px;
  }
}

.mdl-layout__header-row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.mdl-layout__header--scroll .mdl-layout__header-row {
  width: 100%;
}

.mdl-layout__header-row .mdl-navigation {
  margin: 0;
  padding: 0;
  height: 64px;
  -ms-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -ms-flex-align: center;
  -moz-box-align: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation {
    height: 56px;
  }
}

.mdl-layout__header-row .mdl-navigation__link {
  display: block;
  color: #fff;
  line-height: 64px;
  padding: 0 24px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation__link {
    line-height: 56px;
    padding: 0 16px;
  }
}

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-layout__obfuscator.is-visible {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

@supports (pointer-events: auto) {
  .mdl-layout__obfuscator {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }

  .mdl-layout__obfuscator.is-visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  position: relative;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-flex-positive: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

.mdl-layout--fixed-drawer > .mdl-layout__content {
  margin-left: 240px;
}

.mdl-layout__container.has-scrolling-header .mdl-layout__content {
  overflow: visible;
}

@media screen and (max-width: 1024px) {
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 0;
  }

  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% - 112px);
  padding: 0 0 0 56px;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  background-color: #3f51b5;
  overflow-y: hidden;
  overflow-x: scroll;
}

.mdl-layout__tab-bar::-webkit-scrollbar {
  display: none;
}

.mdl-layout--no-drawer-button .mdl-layout__tab-bar {
  padding-left: 16px;
  width: calc(100% - 32px);
}

@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar {
    width: calc(100% - 60px);
    padding: 0 0 0 60px;
  }

  .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    width: calc(100% - 8px);
    padding-left: 4px;
  }
}

.mdl-layout--fixed-tabs .mdl-layout__tab-bar {
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  -ms-flex-positive: 0;
  -moz-box-flex: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.mdl-layout__container > .mdl-layout__tab-bar-container {
  position: absolute;
  top: 0;
  left: 0;
}

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: #3f51b5;
  color: transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button, .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
  width: 16px;
}

.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons, .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
  position: relative;
  left: -4px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar-button {
    width: 60px;
  }
}

.mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
  display: none;
}

.mdl-layout__tab-bar-button .material-icons {
  line-height: 48px;
}

.mdl-layout__tab-bar-button.is-active {
  color: #fff;
}

.mdl-layout__tab-bar-left-button {
  left: 0;
}

.mdl-layout__tab-bar-right-button {
  right: 0;
}

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px;
  float: left;
  position: relative;
  display: block;
  -ms-flex-positive: 0;
  -moz-box-flex: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__tab {
    padding: 0 12px;
  }
}

.mdl-layout--fixed-tabs .mdl-layout__tab {
  float: none;
  -ms-flex-positive: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  padding: 0;
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active {
  color: #fff;
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: #2f2f2f;
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

.mdl-layout__tab .mdl-layout__tab-ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
  background-color: #fff;
}

.mdl-layout__tab-panel {
  display: block;
}

.mdl-layout.is-upgraded .mdl-layout__tab-panel {
  display: none;
}

.mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
  display: block;
}

.mdl-radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 24px;
  margin: 0;
  padding-left: 0;
}

.mdl-radio.is-upgraded {
  padding-left: 24px;
}

.mdl-radio__button {
  line-height: 24px;
}

.mdl-radio.is-upgraded .mdl-radio__button {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-radio__outer-circle {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 50%;
  z-index: 2;
}

.mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #3f51b5;
}

.mdl-radio__outer-circle fieldset[disabled] .mdl-radio, .mdl-radio.is-disabled .mdl-radio__outer-circle {
  border: 2px solid rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.mdl-radio__inner-circle {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 8px;
  left: 4px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transform: scale(0, 0);
  border-radius: 50%;
  background: #3f51b5;
}

.mdl-radio.is-checked .mdl-radio__inner-circle {
  transform: scale(1, 1);
}

fieldset[disabled] .mdl-radio .mdl-radio__inner-circle, .mdl-radio.is-disabled .mdl-radio__inner-circle {
  background: rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.mdl-radio.is-focused .mdl-radio__inner-circle {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

.mdl-radio__label {
  cursor: pointer;
}

fieldset[disabled] .mdl-radio .mdl-radio__label, .mdl-radio.is-disabled .mdl-radio__label {
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
}

.mdl-radio__ripple-container {
  position: absolute;
  z-index: 2;
  top: -9px;
  left: -13px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-radio__ripple-container .mdl-ripple {
  background: #3f51b5;
}

fieldset[disabled] .mdl-radio .mdl-radio__ripple-container, .mdl-radio.is-disabled .mdl-radio__ripple-container {
  cursor: auto;
}

fieldset[disabled] .mdl-radio .mdl-radio__ripple-container .mdl-ripple, .mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
  background: 0 0;
}

_:-ms-input-placeholder, :root .mdl-slider.mdl-slider.is-upgraded {
  -ms-appearance: none;
  height: 32px;
  margin: 0;
}

.mdl-slider {
  width: calc(100% - 40px);
  margin: 0 20px;
}

.mdl-slider.is-upgraded {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2px;
  background: 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  padding: 0;
  color: #3f51b5;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  z-index: 1;
  cursor: pointer;
}

.mdl-slider.is-upgraded::-moz-focus-outer {
  border: 0;
}

.mdl-slider.is-upgraded::-ms-tooltip {
  display: none;
}

.mdl-slider.is-upgraded::-webkit-slider-runnable-track {
  background: 0 0;
}

.mdl-slider.is-upgraded::-moz-range-track {
  background: 0 0;
  border: none;
}

.mdl-slider.is-upgraded::-ms-track {
  background: 0 0;
  color: transparent;
  height: 2px;
  width: 100%;
  border: none;
}

.mdl-slider.is-upgraded::-ms-fill-lower {
  padding: 0;
  background: linear-gradient(to right, transparent, transparent 16px, #3f51b5 16px, #3f51b5 0);
}

.mdl-slider.is-upgraded::-ms-fill-upper {
  padding: 0;
  background: linear-gradient(to left, transparent, transparent 16px, rgba(0, 0, 0, 0.26) 16px, rgba(0, 0, 0, 0.26) 0);
}

.mdl-slider.is-upgraded::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #3f51b5;
  border: none;
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-slider.is-upgraded::-moz-range-thumb {
  -moz-appearance: none;
  width: 12px;
  height: 12px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
  background-image: none;
  background: #3f51b5;
  border: none;
}

.mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(63, 81, 181, 0.26);
}

.mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(63, 81, 181, 0.26);
}

.mdl-slider.is-upgraded:active::-webkit-slider-thumb {
  background-image: none;
  background: #3f51b5;
  transform: scale(1.5);
}

.mdl-slider.is-upgraded:active::-moz-range-thumb {
  background-image: none;
  background: #3f51b5;
  transform: scale(1.5);
}

.mdl-slider.is-upgraded::-ms-thumb {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background: #3f51b5;
  transform: scale(0.375);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, #3f51b5 0%, #3f51b5 37.5%, rgba(63, 81, 181, 0.26) 37.5%, rgba(63, 81, 181, 0.26) 100%);
  transform: scale(1);
}

.mdl-slider.is-upgraded:active::-ms-thumb {
  background: #3f51b5;
  transform: scale(0.5625);
}

.mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
  border: 2px solid rgba(0, 0, 0, 0.26);
  background: 0 0;
}

.mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
  border: 2px solid rgba(0, 0, 0, 0.26);
  background: 0 0;
}

.mdl-slider.is-upgraded.is-lowest-value + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12);
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12);
}

.mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
  border: 1.6px solid rgba(0, 0, 0, 0.26);
  transform: scale(1.5);
}

.mdl-slider.is-upgraded.is-lowest-value:active + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 9px;
}

.mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
  border: 1.5px solid rgba(0, 0, 0, 0.26);
  transform: scale(1.5);
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
  background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0, 0, 0, 0.26) 66.67%, rgba(0, 0, 0, 0.26) 100%);
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0.26) 25%, rgba(0, 0, 0, 0.26) 37.5%, rgba(0, 0, 0, 0.12) 37.5%, rgba(0, 0, 0, 0.12) 100%);
  transform: scale(1);
}

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
  transform: scale(0.5625);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0, 0, 0, 0.26) 77.78%, rgba(0, 0, 0, 0.26) 100%);
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
  background: 0 0;
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
  margin-left: 9px;
}

.mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
  transform: scale(0.667);
  background: rgba(0, 0, 0, 0.26);
}

.mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded:disabled::-moz-range-thumb {
  transform: scale(0.667);
  background: rgba(0, 0, 0, 0.26);
}

.mdl-slider.is-upgraded:disabled + .mdl-slider__background-flex > .mdl-slider__background-lower {
  background-color: rgba(0, 0, 0, 0.26);
  left: -6px;
}

.mdl-slider.is-upgraded:disabled + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
  border: 3px solid rgba(0, 0, 0, 0.26);
  background: 0 0;
  transform: scale(0.667);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
  border: 3px solid rgba(0, 0, 0, 0.26);
  background: 0 0;
  transform: scale(0.667);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:active + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}

.mdl-slider.is-upgraded:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded:disabled:active::-ms-thumb, .mdl-slider.is-upgraded:disabled::-ms-thumb {
  transform: scale(0.25);
  background: rgba(0, 0, 0, 0.26);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
  transform: scale(0.25);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.26) 100%);
}

.mdl-slider.is-upgraded:disabled::-ms-fill-lower {
  margin-right: 6px;
  background: linear-gradient(to right, transparent, transparent 25px, rgba(0, 0, 0, 0.26) 25px, rgba(0, 0, 0, 0.26) 0);
}

.mdl-slider.is-upgraded:disabled::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider__ie-container {
  height: 18px;
  overflow: visible;
  border: none;
  margin: none;
  padding: none;
}

.mdl-slider__container {
  height: 18px;
  position: relative;
  -ms-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
}

.mdl-slider__container, .mdl-slider__background-flex {
  background: 0 0;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
}

.mdl-slider__background-flex {
  position: absolute;
  height: 2px;
  width: calc(100% - 52px);
  top: 50%;
  left: 0;
  margin: 0 26px;
  overflow: hidden;
  border: 0;
  padding: 0;
  transform: translate(0, -1px);
}

.mdl-slider__background-lower {
  background: #3f51b5;
}

.mdl-slider__background-lower, .mdl-slider__background-upper {
  -ms-flex: 0;
  -moz-box-flex: 0;
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
}

.mdl-slider__background-upper {
  background: rgba(0, 0, 0, 0.26);
  transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-snackbar {
  position: fixed;
  bottom: 0;
  left: 50%;
  cursor: default;
  background-color: #323232;
  z-index: 3;
  display: block;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: space-between;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  will-change: transform;
  transform: translate(0, 80px);
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
  pointer-events: none;
}

@media (max-width: 479px) {
  .mdl-snackbar {
    width: 100%;
    left: 0;
    min-height: 48px;
    max-height: 80px;
  }
}

@media (min-width: 480px) {
  .mdl-snackbar {
    min-width: 288px;
    max-width: 568px;
    border-radius: 2px;
    transform: translate(-50%, 80px);
  }
}

.mdl-snackbar--active {
  transform: translate(0, 0);
  pointer-events: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
}

@media (min-width: 480px) {
  .mdl-snackbar--active {
    transform: translate(-50%, 0);
  }
}

.mdl-snackbar__text {
  padding: 14px 12px 14px 24px;
  vertical-align: middle;
  color: #fff;
  float: left;
}

.mdl-snackbar__action {
  background: 0 0;
  border: none;
  color: #2f2f2f;
  float: right;
  padding: 14px 24px 14px 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  outline: none;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.mdl-snackbar__action::-moz-focus-inner {
  border: 0;
}

.mdl-snackbar__action:not([aria-hidden]) {
  opacity: 1;
  pointer-events: auto;
}

.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
}

.mdl-spinner:not(.is-upgraded).is-active:after {
  content: "Loading...";
}

.mdl-spinner.is-upgraded.is-active {
  animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite;
}

@keyframes mdl-spinner__container-rotate {
  to {
    transform: rotate(360deg);
  }
}

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mdl-spinner__layer-1 {
  border-color: #42a5f5;
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-1 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-2 {
  border-color: #f44336;
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-2 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-3 {
  border-color: #fdd835;
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-3 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-4 {
  border-color: #4caf50;
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-4 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

@keyframes mdl-spinner__layer-1-fade-in-out {
  from, 25% {
    opacity: .99;
  }
  26%, 89% {
    opacity: 0;
  }
  90%, 100% {
    opacity: .99;
  }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
  from, 15% {
    opacity: 0;
  }
  25%, 50% {
    opacity: .99;
  }
  51% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
  from, 40% {
    opacity: 0;
  }
  50%, 75% {
    opacity: .99;
  }
  76% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
  from, 65% {
    opacity: 0;
  }
  75%, 90% {
    opacity: .99;
  }
  100% {
    opacity: 0;
  }
}

.mdl-spinner__gap-patch {
  position: absolute;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%;
}

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.mdl-spinner__circle-clipper.mdl-spinner__left {
  float: left;
}

.mdl-spinner__circle-clipper.mdl-spinner__right {
  float: right;
}

.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%;
}

.mdl-spinner__circle {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mdl-spinner__left .mdl-spinner__circle {
  border-right-color: transparent !important;
  transform: rotate(129deg);
}

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes mdl-spinner__left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes mdl-spinner__right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}

.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mdl-switch.is-upgraded {
  padding-left: 28px;
}

.mdl-switch__input {
  line-height: 24px;
}

.mdl-switch.is-upgraded .mdl-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-switch__track {
  background: rgba(0, 0, 0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer;
}

.mdl-switch.is-checked .mdl-switch__track {
  background: rgba(63, 81, 181, 0.5);
}

.mdl-switch__track fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__track {
  background: rgba(0, 0, 0, 0.12);
  cursor: auto;
}

.mdl-switch__thumb {
  background: #fafafa;
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left;
}

.mdl-switch.is-checked .mdl-switch__thumb {
  background: #3f51b5;
  left: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-switch__thumb fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__thumb {
  background: #bdbdbd;
  cursor: auto;
}

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-4px, -4px);
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
}

.mdl-switch.is-focused .mdl-switch__focus-helper {
  box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

.mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
  box-shadow: 0 0 0 20px rgba(63, 81, 181, 0.26);
  background-color: rgba(63, 81, 181, 0.26);
}

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px;
}

.mdl-switch__label fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__label {
  color: #bdbdbd;
  cursor: auto;
}

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
  transition-duration: .4s;
  transition-timing-function: step-end;
  transition-property: left;
}

.mdl-switch__ripple-container .mdl-ripple {
  background: #3f51b5;
}

.mdl-switch__ripple-container fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__ripple-container {
  cursor: auto;
}

fieldset[disabled] .mdl-switch .mdl-switch__ripple-container .mdl-ripple, .mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
  background: 0 0;
}

.mdl-switch.is-checked .mdl-switch__ripple-container {
  left: 2px;
}

.mdl-tabs {
  display: block;
  width: 100%;
}

.mdl-tabs__tab-bar {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -ms-flex-pack: center;
  -moz-box-pack: center;
  justify-content: center;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  -ms-flex-align: start;
  -moz-box-align: start;
  align-items: flex-start;
  height: 48px;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
}

.mdl-tabs__tab {
  margin: 0;
  border: none;
  padding: 0 24px;
  float: left;
  position: relative;
  display: block;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
  overflow: hidden;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
  color: rgba(0, 0, 0, 0.87);
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: #3f51b5;
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

.mdl-tabs__tab .mdl-tabs__ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
  background: #3f51b5;
}

.mdl-tabs__panel {
  display: block;
}

.mdl-tabs.is-upgraded .mdl-tabs__panel {
  display: none;
}

.mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
  display: block;
}

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
}

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px;
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}

.mdl-textfield--expandable .mdl-button--icon {
  top: 16px;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
}

.mdl-textfield__input[type="number"] {
  -moz-appearance: textfield;
}

.mdl-textfield__input[type="number"]::-webkit-inner-spin-button, .mdl-textfield__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: #e11c41;
  box-shadow: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__input, .mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.mdl-textfield.is-dirty .mdl-textfield__label, .mdl-textfield.has-placeholder .mdl-textfield__label {
  visibility: hidden;
}

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__label, .mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  color: #3f51b5;
  font-size: 12px;
  top: 4px;
  visibility: visible;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #e11c41;
  font-size: 12px;
}

.mdl-textfield__label:after {
  background-color: #3f51b5;
  bottom: 20px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #e11c41;
}

.mdl-textfield__error {
  color: #e11c41;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: .1px;
}

.mdl-textfield.is-focused .mdl-textfield__expandable-holder, .mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
  max-width: 600px;
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

.mdl-tooltip {
  transform: scale(0);
  transform-origin: top center;
  z-index: 999;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center;
}

.mdl-tooltip.is-active {
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mdl-tooltip--large {
  line-height: 14px;
  font-size: 14px;
  padding: 16px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}

.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

.mdl-grid {
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-flow: row wrap;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-flow: row wrap;
  margin: 0 auto;
  -ms-flex-align: stretch;
  -moz-box-align: stretch;
  align-items: stretch;
}

.mdl-grid.mdl-grid--no-spacing {
  padding: 0;
}

.mdl-cell {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-cell--top {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.mdl-cell--middle {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.mdl-cell--bottom {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.mdl-cell--stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0;
}

.mdl-cell--order-1 {
  -ms-flex-order: 1;
  -moz-box-ordinal-group: 2;
  order: 1;
}

.mdl-cell--order-2 {
  -ms-flex-order: 2;
  -moz-box-ordinal-group: 3;
  order: 2;
}

.mdl-cell--order-3 {
  -ms-flex-order: 3;
  -moz-box-ordinal-group: 4;
  order: 3;
}

.mdl-cell--order-4 {
  -ms-flex-order: 4;
  -moz-box-ordinal-group: 5;
  order: 4;
}

.mdl-cell--order-5 {
  -ms-flex-order: 5;
  -moz-box-ordinal-group: 6;
  order: 5;
}

.mdl-cell--order-6 {
  -ms-flex-order: 6;
  -moz-box-ordinal-group: 7;
  order: 6;
}

.mdl-cell--order-7 {
  -ms-flex-order: 7;
  -moz-box-ordinal-group: 8;
  order: 7;
}

.mdl-cell--order-8 {
  -ms-flex-order: 8;
  -moz-box-ordinal-group: 9;
  order: 8;
}

.mdl-cell--order-9 {
  -ms-flex-order: 9;
  -moz-box-ordinal-group: 10;
  order: 9;
}

.mdl-cell--order-10 {
  -ms-flex-order: 10;
  -moz-box-ordinal-group: 11;
  order: 10;
}

.mdl-cell--order-11 {
  -ms-flex-order: 11;
  -moz-box-ordinal-group: 12;
  order: 11;
}

.mdl-cell--order-12 {
  -ms-flex-order: 12;
  -moz-box-ordinal-group: 13;
  order: 12;
}

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px;
  }

  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }

  .mdl-cell--hide-phone {
    display: none !important;
  }

  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    -ms-flex-order: 1;
    -moz-box-ordinal-group: 2;
    order: 1;
  }

  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    -ms-flex-order: 2;
    -moz-box-ordinal-group: 3;
    order: 2;
  }

  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    -ms-flex-order: 3;
    -moz-box-ordinal-group: 4;
    order: 3;
  }

  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    -ms-flex-order: 4;
    -moz-box-ordinal-group: 5;
    order: 4;
  }

  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    -ms-flex-order: 5;
    -moz-box-ordinal-group: 6;
    order: 5;
  }

  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    -ms-flex-order: 6;
    -moz-box-ordinal-group: 7;
    order: 6;
  }

  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    -ms-flex-order: 7;
    -moz-box-ordinal-group: 8;
    order: 7;
  }

  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    -ms-flex-order: 8;
    -moz-box-ordinal-group: 9;
    order: 8;
  }

  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    -ms-flex-order: 9;
    -moz-box-ordinal-group: 10;
    order: 9;
  }

  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    -ms-flex-order: 10;
    -moz-box-ordinal-group: 11;
    order: 10;
  }

  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    -ms-flex-order: 11;
    -moz-box-ordinal-group: 12;
    order: 11;
  }

  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    -ms-flex-order: 12;
    -moz-box-ordinal-group: 13;
    order: 12;
  }

  .mdl-cell--1-col, .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing > .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: 25%;
  }

  .mdl-cell--2-col, .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing > .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: 50%;
  }

  .mdl-cell--3-col, .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing > .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: 75%;
  }

  .mdl-cell--4-col, .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing > .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: 100%;
  }

  .mdl-cell--5-col, .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing > .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: 100%;
  }

  .mdl-cell--6-col, .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing > .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: 100%;
  }

  .mdl-cell--7-col, .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing > .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: 100%;
  }

  .mdl-cell--8-col, .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing > .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: 100%;
  }

  .mdl-cell--9-col, .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing > .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: 100%;
  }

  .mdl-cell--10-col, .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing > .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: 100%;
  }

  .mdl-cell--11-col, .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing > .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: 100%;
  }

  .mdl-cell--12-col, .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing > .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: 100%;
  }

  .mdl-cell--1-offset, .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: 25%;
  }

  .mdl-cell--2-offset, .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: 50%;
  }

  .mdl-cell--3-offset, .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: 75%;
  }
}

@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px;
  }

  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell {
    width: 50%;
  }

  .mdl-cell--hide-tablet {
    display: none !important;
  }

  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    -ms-flex-order: 1;
    -moz-box-ordinal-group: 2;
    order: 1;
  }

  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    -ms-flex-order: 2;
    -moz-box-ordinal-group: 3;
    order: 2;
  }

  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    -ms-flex-order: 3;
    -moz-box-ordinal-group: 4;
    order: 3;
  }

  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    -ms-flex-order: 4;
    -moz-box-ordinal-group: 5;
    order: 4;
  }

  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    -ms-flex-order: 5;
    -moz-box-ordinal-group: 6;
    order: 5;
  }

  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    -ms-flex-order: 6;
    -moz-box-ordinal-group: 7;
    order: 6;
  }

  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    -ms-flex-order: 7;
    -moz-box-ordinal-group: 8;
    order: 7;
  }

  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    -ms-flex-order: 8;
    -moz-box-ordinal-group: 9;
    order: 8;
  }

  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    -ms-flex-order: 9;
    -moz-box-ordinal-group: 10;
    order: 9;
  }

  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    -ms-flex-order: 10;
    -moz-box-ordinal-group: 11;
    order: 10;
  }

  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    -ms-flex-order: 11;
    -moz-box-ordinal-group: 12;
    order: 11;
  }

  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    -ms-flex-order: 12;
    -moz-box-ordinal-group: 13;
    order: 12;
  }

  .mdl-cell--1-col, .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing > .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: 12.5%;
  }

  .mdl-cell--2-col, .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing > .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: 25%;
  }

  .mdl-cell--3-col, .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing > .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: 37.5%;
  }

  .mdl-cell--4-col, .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing > .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: 50%;
  }

  .mdl-cell--5-col, .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing > .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: 62.5%;
  }

  .mdl-cell--6-col, .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing > .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: 75%;
  }

  .mdl-cell--7-col, .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing > .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: 87.5%;
  }

  .mdl-cell--8-col, .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing > .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: 100%;
  }

  .mdl-cell--9-col, .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing > .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: 100%;
  }

  .mdl-cell--10-col, .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing > .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: 100%;
  }

  .mdl-cell--11-col, .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing > .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: 100%;
  }

  .mdl-cell--12-col, .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing > .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: 100%;
  }

  .mdl-cell--1-offset, .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: 12.5%;
  }

  .mdl-cell--2-offset, .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: 25%;
  }

  .mdl-cell--3-offset, .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: 37.5%;
  }

  .mdl-cell--4-offset, .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: 50%;
  }

  .mdl-cell--5-offset, .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: 62.5%;
  }

  .mdl-cell--6-offset, .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: 75%;
  }

  .mdl-cell--7-offset, .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: 87.5%;
  }
}

@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px;
  }

  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell {
    width: 33.3333333333%;
  }

  .mdl-cell--hide-desktop {
    display: none !important;
  }

  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    -ms-flex-order: 1;
    -moz-box-ordinal-group: 2;
    order: 1;
  }

  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    -ms-flex-order: 2;
    -moz-box-ordinal-group: 3;
    order: 2;
  }

  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    -ms-flex-order: 3;
    -moz-box-ordinal-group: 4;
    order: 3;
  }

  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    -ms-flex-order: 4;
    -moz-box-ordinal-group: 5;
    order: 4;
  }

  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    -ms-flex-order: 5;
    -moz-box-ordinal-group: 6;
    order: 5;
  }

  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    -ms-flex-order: 6;
    -moz-box-ordinal-group: 7;
    order: 6;
  }

  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    -ms-flex-order: 7;
    -moz-box-ordinal-group: 8;
    order: 7;
  }

  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    -ms-flex-order: 8;
    -moz-box-ordinal-group: 9;
    order: 8;
  }

  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    -ms-flex-order: 9;
    -moz-box-ordinal-group: 10;
    order: 9;
  }

  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    -ms-flex-order: 10;
    -moz-box-ordinal-group: 11;
    order: 10;
  }

  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    -ms-flex-order: 11;
    -moz-box-ordinal-group: 12;
    order: 11;
  }

  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    -ms-flex-order: 12;
    -moz-box-ordinal-group: 13;
    order: 12;
  }

  .mdl-cell--1-col, .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing > .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: 8.3333333333%;
  }

  .mdl-cell--2-col, .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing > .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: 16.6666666667%;
  }

  .mdl-cell--3-col, .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing > .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: 25%;
  }

  .mdl-cell--4-col, .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing > .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: 33.3333333333%;
  }

  .mdl-cell--5-col, .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing > .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: 41.6666666667%;
  }

  .mdl-cell--6-col, .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing > .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: 50%;
  }

  .mdl-cell--7-col, .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing > .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: 58.3333333333%;
  }

  .mdl-cell--8-col, .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing > .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: 66.6666666667%;
  }

  .mdl-cell--9-col, .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing > .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: 75%;
  }

  .mdl-cell--10-col, .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing > .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: 83.3333333333%;
  }

  .mdl-cell--11-col, .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing > .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: 91.6666666667%;
  }

  .mdl-cell--12-col, .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px);
  }

  .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing > .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: 100%;
  }

  .mdl-cell--1-offset, .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: 8.3333333333%;
  }

  .mdl-cell--2-offset, .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: 16.6666666667%;
  }

  .mdl-cell--3-offset, .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: 25%;
  }

  .mdl-cell--4-offset, .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: 33.3333333333%;
  }

  .mdl-cell--5-offset, .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: 41.6666666667%;
  }

  .mdl-cell--6-offset, .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: 50%;
  }

  .mdl-cell--7-offset, .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: 58.3333333333%;
  }

  .mdl-cell--8-offset, .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: 66.6666666667%;
  }

  .mdl-cell--9-offset, .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: 75%;
  }

  .mdl-cell--10-offset, .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: 83.3333333333%;
  }

  .mdl-cell--11-offset, .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px);
  }

  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset, .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: 91.6666666667%;
  }
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  margin-right: 20px;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  background-color: #f5f5f5;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

/** Fonts **/
/** Paths **/
/** Colors **/
/** Gray **/
/** White **/
/** Black **/
/** Rest **/
/** Widths **/
/** Tooltiplabel **/
/** Media queries breakpoints **/
@font-face {
  font-family: 'Antonio';
  src: url("./fonts/antonio/antonio-bold-webfont.eot");
  src: url("./fonts/antonio/antonio-bold-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/antonio/antonio-bold-webfont.woff2") format("woff2"), url("./fonts/antonio/antonio-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('./fonts/oswald/l1cOQ90roY9yC7voEhngDBJtnKITppOI_IvcXXDNrsc.woff2'), url('./fonts/oswald/l1cOQ90roY9yC7voEhngDBJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('./fonts/oswald/HqHm7BVC_nzzTui2lzQTDVtXRa8TVwTICgirnJhmVJw.woff2'), url("./fonts/oswald/HqHm7BVC_nzzTui2lzQTDVtXRa8TVwTICgirnJhmVJw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('./fonts/oswald/yg0glPPxXUISnKUejCX4qfesZW2xOQ-xsNqO47m55DA.woff2'), url('./fonts/oswald/yg0glPPxXUISnKUejCX4qfesZW2xOQ-xsNqO47m55DA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('./fonts/oswald/pEobIV_lL25TKBpqVI_a2w.woff2'), url('./fonts/oswald/pEobIV_lL25TKBpqVI_a2w.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('./fonts/oswald/cgaIrkaP9Empe8_PwXbajBJtnKITppOI_IvcXXDNrsc.woff2'), url('./fonts/oswald/cgaIrkaP9Empe8_PwXbajBJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('./fonts/oswald/KuTkTNzljLi-9-e4QiI83ltXRa8TVwTICgirnJhmVJw.woff2'), url('./fonts/oswald/KuTkTNzljLi-9-e4QiI83ltXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: local('Oswald SemiBold'), local('./fonts/oswald/FVV1uRqs2-G7nbyX6T7UGRJtnKITppOI_IvcXXDNrsc.woff2'), url('./fonts/oswald/FVV1uRqs2-G7nbyX6T7UGRJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: local('Oswald SemiBold'), local('./fonts/oswald/y5-5zezrZlJHkmIVxRH3BVtXRa8TVwTICgirnJhmVJw.woff2'), url('./fonts/oswald/y5-5zezrZlJHkmIVxRH3BVtXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('./fonts/oswald/dI-qzxlKVQA6TUC5RKSb3xJtnKITppOI_IvcXXDNrsc.woff2'), url('./fonts/oswald/dI-qzxlKVQA6TUC5RKSb3xJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('./fonts/oswald/bH7276GfdCjMjApa_dkG6VtXRa8TVwTICgirnJhmVJw.woff2'), url('./fonts/oswald/bH7276GfdCjMjApa_dkG6VtXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('./fonts/roboto/Pru33qjShpZSmG3z6VYwnRJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('./fonts/roboto/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2'), url('./fonts/roboto/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('./fonts/roboto/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2'), url('./fonts/roboto/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('./fonts/roboto/CWB0XYA8bzo0kSThX0UTuA.woff2'), url('./fonts/roboto/CWB0XYA8bzo0kSThX0UTuA.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('./fonts/roboto/oOeFwZNlrTefzLYmlVV1UBJtnKITppOI_IvcXXDNrsc.woff2'), url('./fonts/roboto/oOeFwZNlrTefzLYmlVV1UBJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('./fonts/roboto/RxZJdnzeo3R5zSexge8UUVtXRa8TVwTICgirnJhmVJw.woff2'), url('./fonts/roboto/RxZJdnzeo3R5zSexge8UUVtXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('./fonts/roboto/97uahxiqZRoncBaCEI3aWxJtnKITppOI_IvcXXDNrsc.woff2'), url('./fonts/roboto/97uahxiqZRoncBaCEI3aWxJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('./fonts/roboto/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2'), url('./fonts/roboto/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica LT Pro'), local('./fonts/helvetica/helvetica_latin_ext_normal.woff2'), url('./fonts/helvetica/helvetica_latin_ext_normal.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica LT Pro'), local('./fonts/helvetica/helvetica_latin_normal.woff2'), url('./fonts/helvetica/helvetica_latin_normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  src: local('Helvetica LT Pro Bold'), local('./fonts/helvetica/helvetica_latin_ext_bold.woff2'), url('./fonts/helvetica/helvetica_latin_ext_bold.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  src: local('Helvetica LT Pro Bold'), local('./fonts/helvetica/helvetica_latin_bold.woff2'), url('./fonts/helvetica/helvetica_latin_bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
}

html, body {
  background: #f9f9f9;
  font-family: "Oswaldo", sans-serif;
  font-size: 12px;
}

a {
  text-decoration: none;
}

.center-box {
  max-width: 4000px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 0 30px;
}

.fw-300 {
  font-weight: 300;
}

.fw-700 {
  font-weight: 700;
}

.margin-0 {
  margin: 0;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.mdl-button {
  height: 30px;
  padding: 0 10px;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}

.mdl-button-normal {
  text-transform: none;
}

.mdl-button-border {
  border: 1px solid #ddd;
  box-shadow: none;
  background: #f9f9f9;
}

.mdl-button-static {
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24) !important;
  cursor: default;
}

.mdl-button--white {
  background: #fff;
}

.mdl-button--gray {
  background: #f8f8f8;
}

.mdl-button.button-to-left {
  margin-right: auto;
}

.mdl-button.button-to-right {
  margin-left: auto;
}

.mdl-button-static.mdl-button--white:hover {
  background: #fff !important;
}

.mdl-button-static.mdl-button--gray:hover {
  background: #f8f8f8 !important;
}

.button--flex {
  display: flex;
}

.button--flex .material-icons {
  font-size: 18px;
  line-height: 1.5;
}

.font-oswald {
  font-family: "Oswald", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.error-msg {
  color: #e11c41;
}

.success-msg {
  color: #119724;
}

.warning-msg {
  color: #ed9d12;
}

.inprogress-msg {
  color: #666;
}

.virtual-msg {
  color: #b1bbc9;
}

.changed-msg {
  color: #7c7c7c;
}

.color-white {
  color: #fff;
}

.flexbox {
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
}

.flex-center {
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-end {
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.protocolList {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2 {
  margin-bottom: 0.5em;
  font-family: "Oswald", sans-serif;
  font-size: 27px;
}

input, div {
  font-family: "Oswald", sans-serif;
}

/* TOPBAR */
.topbar {
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.logo-box {
  margin: 0 auto 15px 0;
  padding-top: 10px;
}

.logo {
  display: block;
  height: 63px;
  padding-top: 5px;
  padding-left: 70px;
  padding-bottom: 5px;
  background: url("img/logos/PLAYLISTER.png") no-repeat 0 50%;
  background-size: contain;
}

.logo-label {
  display: block;
  padding-top: 2px;
  padding-left: 5px;
  font-family: "Antonio", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  color: #1a5eab;
  margin-top: 8px;
  border: none;
}

.menu {
  position: relative !important;
  padding-top: 0;
  font-family: "Oswald", sans-serif;
}

.menu a {
  margin: 0 28px;
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
}

.menu img {
  margin: 0 15px;
  height: 33px;
  border-radius: 50%;
}

.lang-menu .p-menu {
  width: auto;
}

.p-menu .p-menuitem-link {
  padding: 1rem 0.7rem;
  margin: 0;
}

.menu a.active,
.menu a:active,
.menu a:focus,
.menu a:hover {
  color: #7c7c7c;
}

.userbar {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.userbar-box {
  width: 405px;
  margin: 0 12px 0 80px;
  padding: 6px 8px 11px 6px;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 3px 2px #e6e6e6;
  background: #fff;
}

.userbar-box * {
  font-size: 14px;
}

.menu-container {
  position: relative;
}

.userbar-box .mdl-button {
  margin-left: 4px;
  padding: 0 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.userbar-box .mdl-button .material-icons {
  margin-left: -4px;
  font-size: 18px;
  vertical-align: middle;
}

.userbar-box .mdl-button img {
  display: inline-block;
  vertical-align: middle;
}

.userbar-box .mdl-button .menu-label {
  vertical-align: middle;
}

.userbar-box .custom-badge {
  margin: 3px 4px 0 5px;
}

.lang-box {
  white-space: nowrap;
  padding-top: 10px;
}

/* CUSTOM BADGE */
.custom-badge {
  display: block;
  width: 22px;
  height: 23px;
  border-radius: 4px;
  background: #fca81b;
  font-weight: 500;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  line-height: 23px;
  text-align: center;
  color: #000;
}

/* CHIPS */
.mdl-chip {
  height: auto;
  padding: 7px 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  line-height: 11px;
}

.mdl-chip.mdl-chip--icononly {
  padding: 0;
}

.mdl-chip__text {
  font-family: "Oswald", sans-serif;
  font-size: 11px;
  line-height: 11px;
}

.mdl-chip__action {
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.mdl-chip--icononly .mdl-chip__action {
  width: auto;
  height: 28px;
  margin-left: 0;
  padding: 7px 8px;
}

.mdl-chip__action .material-icons {
  font-size: 14px;
  color: #a1a1a1;
}

.mdl-chip--icononly .mdl-chip__action .material-icons {
  margin-top: -1px;
  font-size: 16px;
}

/* CARDS */
.custom-panel {
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  min-height: auto;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  background: #fff;
}

.custom-panel.flex-center {
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* WEEKPICKER */
.weekpicker-box .material-icons {
  /*margin-left: 5px;*/
  font-size: 18px;
  vertical-align: middle;
}

.weekpicker {
  color: #888;
}

.mat-form-field.datepicker-area {
  display: block;
  width: 150px;
}

/* WEEKNAV */
.nav-prev-next-box {
  margin-left: auto;
}

.nav-prev-next-box .mdl-button {
  min-width: 0;
  padding: 0 6px;
  background: #f9f9f9;
}

.nav-prev-next-box .material-icons {
  margin-top: -4px;
  font-size: 18px;
  color: #a1a1a1;
}

/* FOOTER */
.footer-nav {
  margin-bottom: 80px;
  text-align: center;
}

.footer-nav li {
  display: inline-block;
  margin: 0 14px;
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

/* MEDIA QUERIES */
@media (max-width: 1150px) {
  .topbar {
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .menu {
    -moz-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 100%;
  }

  .menu > .list {
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .userbar-box {
    margin-left: 12px;
  }
}

@media (max-width: 800px) {
  .lang-box {
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 125px;
  }

  .userbar {
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

/** Fonts **/
/** Paths **/
/** Colors **/
/** Gray **/
/** White **/
/** Black **/
/** Rest **/
/** Widths **/
/** Tooltiplabel **/
/** Media queries breakpoints **/
/* SCHEDULE */
.schedule-box {
  margin-top: 10px;
  margin-bottom: 70px;
  background: #f8f8f8;
  min-width: 1170px;
}

.schedule-row {
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.schedule-header {
  background: #2f2f2f;
  font-family: "Oswald", sans-serif;
  text-align: center;
  color: #bcbcbc;
}

.schedule-item {
  font-size: 12px !important;
  cursor: default;
  position: relative;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 15px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #d8d8d8;
  background: #fff;
  height: 100%;
}

.schedule-item-cursor-pointer {
  cursor: pointer;
}

.schedule-item-cursor-pointer div {
  cursor: pointer;
}

.schedule-item-container {
  position: relative;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.schedule-item.status-empty {
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
}

.schedule-item-placeholder {
  font-family: "Oswald", sans-serif;
  font-size: 14px;
}

.schedule-item.status-success {
  background: #fbfbfb;
}

.schedule-item.item-main {
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  width: 130px;
  border-left-width: 1px;
  text-align: center;
  background: transparent;
  height: auto;
}

.schedule-header .schedule-item,
.schedule-header .schedule-item.item-main {
  border: 0 none;
  background: #2f2f2f;
  font-size: 13px;
}

.schedule-item:hover {
  z-index: 10;
}

.schedule-header .schedule-item:hover {
  z-index: 1;
}

.schedule-item > * {
  position: relative;
  z-index: 3;
}

.schedule-item-expanded {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #fff;
  transition: all 0.5s;
}

.schedule-item.status-success .schedule-item-expanded {
  display: none;
}

.status-success .schedule-item-expanded {
  background: #fbfbfb;
}

.schedule-item:hover .schedule-item-expanded {
  z-index: 2;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  box-shadow: 0 0 6px 3px #e1e1e1;
}

.schedule-item-date {
  display: none;
}

.channel-logo-box {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.channel-logo {
  display: block;
  margin: auto 0;
  min-height: 50px;
}

.empty-logo {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
}

.empty-logo-size {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  padding: 5px;
}

.schedule-item-label {
  color: #979797;
}

.schedule-item .schedule-item-label .error-msg {
  color: #e11c41;
}

.schedule-item-body {
  justify-content: center;
}

.schedule-item-head {
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.schedule-item-opacity {
  opacity: 0.5;
}

.schedule-item-head.schedule-item-label {
  color: #888;
}

.schedule-item .schedule-item-number {
  color: #2f2f2f;
}

.schedule-item .schedule-item-number.error-msg {
  color: #e11c41;
}

.schedule-item .schedule-item-body .schedule-item-number {
  font-size: 16px;
}

.schedule-item-body-element {
  padding: 0 5px;
  text-align: center;
}

.schedule-item-missing-outern {
  padding: 0 5px;
  text-align: center;
  color: #e11c41
}

.schedule-item-missing-outern :hover {
  pointer: cursor;
}

.schedule-item-missing-inner {
}

.schedule-item-missing-outern:hover .schedule-item-missing-inner {
  color: #8d182c;
}

.schedule-item-footer {
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  font-size: 11px;
}

.item-version {
  margin-left: auto;
}

.item-version .mdl-button,
.item-status .mdl-button {
  min-width: 0;
  padding: 0;
  width: 26px;
}

.item-version-n {
  font-size: 11px;
  font-weight: 700;
}

.item-status .mdl-button .material-icons {
  font-size: 13px;
}

.item-icon-label {
  vertical-align: text-top;
  font-weight: 700;
}

.item-status .mdl-button + .item-icon-label {
  margin-left: 5px;
}

/** Fonts **/
/** Paths **/
/** Colors **/
/** Gray **/
/** White **/
/** Black **/
/** Rest **/
/** Widths **/
/** Tooltiplabel **/
/** Media queries breakpoints **/
/* TABLE */
.table-box {
  margin-top: 10px;
  margin-bottom: 70px;
  background: #f8f8f8;
  box-shadow: 0 2px 3px -2px #c6c6c6;
  font-family: "Oswald", sans-serif;
}

.table-row {
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.table-row.bg-success {
  background: #f4fee5;
}

.table-row.bg-changed {
  background: #fffcf7;
}

.table-row.bg-warning,
.table-body .table-comparing-row.table-row.bg-warning {
  background: #fef5df;
}

.table-header {
  background: #2f2f2f;
  font-family: "Oswald", sans-serif;
  text-align: center;
  color: #bcbcbc;
}

.table-row-toggleable-head {
  background: #f8f8f8;
  width: 100%
}

.table-row-toggleable-head.active {
  background: #f1f1f1;
}

.toggle-row-head.active .toggle-label,
.toggle-row-head .toggle-label-active {
  display: none;
}

.toggle-row-head.active .toggle-label-active {
  display: block;
}

.toggle-label {
  cursor: pointer;
}

.toggle-label-active {
  cursor: pointer;
}

.toggle-row-body {
  display: none;
}

.toggle-row-body.active {
  display: block;
}

.table-row-toggleable-body {
  background: #fff;
}

.comparing-flexbox {
  position: relative;
}

.table-comparing-row {
  -webkit-flex-basis: 50%;
  -ms-preferred-size: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  width: 50%;
}

.table-body .table-comparing-row {
  background: #fff;
}

.table-body .toggle-row-body .table-comparing-row {
  background: #fbfbfb;
}

.comparing-alert {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(100%, -50%);
  min-width: 92px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.comparing-alert-arrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #fca81b;
}

.comparing-alert-label {
  height: 22px;
  margin-left: 11px;
  padding: 4px 7px;
  background: #fca81b;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

.item-akcjewaskie-compare {
  /*padding: 0!important;*/
  /*flex-grow: 1 !important;*/
  /*padding: 10px !important;*/
}

.table-item {
  position: relative;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 15px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #e9e9e9;
  background: #fff;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  cursor: pointer;
  height: 100%;
  min-height: 51px;
}

compare-pad {
  padding: 5px !important;
}


.item-header-wersja {
  /*padding: 15px;*/
}

.table-comparing-row .table-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;
  text-align: center !important;
}

.table-item-shift {
  padding-left: 20px !important;
  text-align: center;
}

.table-comparing-row .table-item-shift {
  padding-left: 20px !important;
}

.table-item span {
}

.toggle-row-head .table-comparing-row .table-item {
  padding-top: 12px;
  padding-bottom: 12px;
}

.indent-items .table-item {
  padding-left: 5px;
}

.table-body .table-comparing-row .table-item {
  border: 0 none;
}

.table-body .table-comparing-row {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #f1f1f1;
}

.toggle-trigger {
  font-weight: 300;
}

.active .toggle-trigger {
  font-weight: 700;
}

.table-item.item-main {
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  width: 130px;
  border-left-width: 1px;
  text-align: center;
  background: transparent;
}

.table-item,
.table-row-toggleable-head .table-item.modify {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  text-align: left;
  background: #FFFFCC;
}

.table-item,
.table-row-toggleable-head .table-item.deleted {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  text-align: left;
  background: #FFCCCC;
}

.table-item,
.table-row-toggleable-head .table-item.added {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  text-align: left;
  background: #99FF99;
}

.table-item,
.table-row-toggleable-head .table-item {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  text-align: left;
  background: #f8f8f8;
}

.table-row-toggleable-head.active .table-item {
  background: #f1f1f1;
}

.table-body .table-comparing-row .table-item,
.table-row-toggleable-body .table-item,
.table-row-toggleable-body .table-item.main-item {
  background: #fff;
}

.bg-success .table-item,
.bg-success .table-item.main-item {
  background: #f4fee5;
}

.bg-changed .table-item,
.bg-changed .table-item.main-item {
  background: #fffcf7;
}

.bg-warning .table-item,
.table-body .table-comparing-row.bg-warning .table-item,
.bg-warning .table-item.main-item,
.table-body .table-comparing-row.bg-warning .table-item.main-item {
  background: #fef5df;
}

.table-body .toggle-row-body .table-item,
.table-body .toggle-row-body .table-item.main-item {
  background: #fbfbfb;
}

.table-item.item-main {
  text-align: left;
}

.table-row-toggleable-head.active .table-item {
  font-weight: 700;
}

.table-item.item-main.item-pozycja {
  width: 60px;
}

.table-header .table-item,
.table-header .table-item.item-main {
  border: 0 none;
  background: #2f2f2f;
  font-size: 13px;
}

.item-status .mdl-button,
.item-compare-changes .mdl-button,
.item-akcje .mdl-button {
  min-width: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.item-status .mdl-button .material-icons,
.item-compare-changes .mdl-button .material-icons {
  font-size: 13px;
}

.table-item .item-icon-label {
  vertical-align: text-top;
  font-weight: 700;
  vertical-align: middle;
}

.item-compare-changes .item-icon-label {
  font-weight: 400;
}

.item-status .mdl-button + .item-icon-label {
  margin-left: 5px;
}

.table-item-header-main {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.table-item-header-label,
.table-item-header-value {
  font-size: 14px;
  font-weight: 300;
  vertical-align: middle;
  color: #fff;
}

.table-comparing-header > * {
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

/* SPECIFIC COLUMNS */
.item-pozycja,
.item-format,
.item-piwo,
.item-hfss,
.item-akcje,
.item-akcjeszerokie,
.item-akcjewaskie,
.item-filler,
.item-poczatek,
.item-dl,
.item-typ,
.item-dlugoscbloku,
.item-poczatekbloku,
.item-statusmat,
.item-idtvn,
.item-idpartnera,
.item-raport
.item-playlista {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.item-nazwa {
  width: 180px;
}

.item-pozycja {
  width: 60px;
}

.item-format,
.item-piwo,
.item-hfss,
.item-playlista {
  width: 70px;
}

.item-akcje {
  width: 70px;
}

.item-akcjeszerokie {
  width: 110px;
  text-align: right;
}

.item-akcjeszerokie a {
  font-weight: 400;
}

.item-akcjewaskie {
  width: 50px;
}

.item-poczatek,
.item-dl {
  width: 110px;
}

.item-typ,
.item-dlugoscbloku,
.item-poczatekbloku {
  width: 160px;
}

.item-statusmat,
.item-statusbloku {
  width: 170px;
}

.item-statusbloku {
  -moz-box-flex: 4;
  -ms-flex-positive: 4;
  flex-grow: 4;
}

.item-idpartnera,
.item-raport {
  width: 100px;
}

.item-idtvn {
  width: 150px;
}

.item-header-datagen {
  margin-left: auto;
}

/* MEDIA QUERIES */
@media (max-width: 1440px) {
  .table-fullfilled .table-item {
    padding: 7px;
  }

  .table-fullfilled .table-item.item-main.item-pozycja,
  .table-fullfilled .item-format,
  .table-fullfilled .item-piwo,
  .table-fullfilled .item-hfss,
  .table-fullfilled .item-akcje {
    width: 54px;
  }

  .table-fullfilled .item-poczatek,
  .table-fullfilled .item-dl {
    width: 94px;
  }

  .table-fullfilled .item-typ {
    width: 144px;
  }

  .table-fullfilled .item-statusmat {
    width: 154px;
  }

  .table-fullfilled .item-idtvn,
  .table-fullfilled .item-idpartnera,
  .table-fullfilled .item-raport {
    width: 84px;
  }
}

@media (max-width: 1340px) {
  .table-comparing-row .item-poczatekbloku {
    width: 110px;
  }

  .table-comparing-row .item-dlugoscbloku {
    width: 130px;
  }
}

@media (max-width: 1200px) {
  .item-nazwa {
    width: 130px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table-header,
  .table-row-toggleable-head,
  .table-row-toggleable-body .table-row,
  .porownanie-playlist-table-box .table-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media (max-width: 1150px) {
  .table-header .table-comparing-row.table-row-to-hide {
    display: none;
  }

  .comparing-flexbox {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #2f2f2f;
  }

  .table-comparing-row {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    width: 100%;
  }

  .toggle-row-head .table-comparing-row:first-child .toggle-trigger {
    display: none;
  }

  .toggle-row-head .table-comparing-row:last-child .toggle-trigger {
    display: block;
    margin-top: -35px;
  }

  .toggle-row-head .table-comparing-row:first-child .item-compare-changes .mdl-button {
    display: none;
  }

  .toggle-row-head .table-comparing-row:last-child .item-compare-changes .mdl-button {
    margin-top: -50px;
  }

  .comparing-alert {
    top: -50%;
  }
}

@media (max-width: 650px) {
  .table-comparing-row .item-nazwa {
    width: 130px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .table-comparing-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

/** Fonts **/
/** Paths **/
/** Colors **/
/** Gray **/
/** White **/
/** Black **/
/** Rest **/
/** Widths **/
/** Tooltiplabel **/
/** Media queries breakpoints **/
/* PRETABLE */
.pre-teble-header {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.table-desc {
  margin-left: auto;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 3px;
}

.modal-form {
  max-height: 60vh;
  overflow: auto;
}

.filters {
  font-family: "Oswald", sans-serif;
  font-size: 11px;
}

.filters .custom-panel {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 7px;
}

.filters input[type="text"],
.filters input[type="email"],
.filters input[type="number"],
.filters input[type="password"],
.filters input[type="date"],
.filters textarea {
  padding: 0 6px;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-family: "Oswald", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
}

.nice-select {
  width: 150px;
  height: 26px;
  padding: 0 24px 0 6px;
  border: 1px solid #ddd !important;
  border-radius: 2px;
  font-family: "Oswald", sans-serif;
  font-size: 11px;
  line-height: 24px;
}

.nice-select .current {
  font-weight: 500;
}

.disabledInput {
  background: #f5f5f5;
  cursor: not-allowed;
}

.nice-select::after {
  top: 4px;
  right: 15px;
  margin-top: 0;
  border: 0 none;
  transform: rotate(0) !important;
  font-family: "Material Icons", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  color: #979797;
  content: "arrow_drop_down";
}

.nice-select.disabled::after {
  color: #f5f5f5;
}

.nice-select .list {
  min-width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 #c6c6c6;
}

.nice-select .list-channel {
  height: 400px;
  overflow-y: scroll;
}

.field-box {
  margin-right: 17px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.field-box-checkbox {
  margin-right: 25px;
  margin-bottom: 6px;
  white-space: nowrap;
}

.field-label {
  text-transform: uppercase;
  font-weight: bold;
}

.filter-button {
  margin-left: 0 !important;
}

.profiles-action-box .filter-button {
  /*margin-left: 8px !important;*/
}

.filter-button .material-icons {
  margin-top: -4px !important;
  margin-left: 4px !important;
  font-size: 17px;
  color: #bcbcbc;
}

/** Fonts **/
/** Paths **/
/** Colors **/
/** Gray **/
/** White **/
/** Black **/
/** Rest **/
/** Widths **/
/** Tooltiplabel **/
/** Media queries breakpoints **/
/* DIALOG */
.success-msg .dialog-show {
  cursor: pointer;
}

.success-msg .dialog-show .material-icons {
  cursor: pointer;
}

.mdl-dialog {
  width: 100%;
  max-width: 925px;
  z-index: 100;
  padding: 0;
  border-radius: 4px;
  font-family: "Oswald", sans-serif;
}

.mdl-dialog__title {
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  background: #f1f1f1;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
}

.dialog-close-icon {
  position: absolute;
  top: 21px;
  right: 13px;
}

.dialog-close-icon .material-icons {
  font-size: 19px;
}

.mdl-dialog__content {
  padding: 0;
  color: #000;
}

.dialog-content {
  padding: 25px 45px 25px;
}

.video-wrapper {
  position: relative;
  height: 464px;
  background: url("./img/video-placeholder.jpg") no-repeat 50% 50%;
  background-size: cover;
}

.video-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.video-play.material-icons {
  font-size: 46px;
  color: #b67701;
}

.video-play-label {
  text-transform: uppercase;
  color: #b67701;
}

.mdl-tabs__tab-bar {
  position: relative;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border: 0 none;
}

.mdl-tabs .mdl-tabs__tab-bar .mdl-tabs__tab {
  position: relative;
  z-index: 2;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #fff;
  border-bottom-color: #e6e6e6;
  color: #2f2f2f;
}

.mdl-tabs .mdl-tabs__tab-bar .mdl-tabs__tab.is-active {
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  border-bottom-color: #fff;
  color: #b1b1b1;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active::after {
  display: none;
}

.tab-line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 1px;
  background: #e6e6e6;
}

.flex-plan > * {
  width: calc(50% - 15px);
}

.file-details-box {
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-left: 1px solid #f1f1f1;
}

.file-details-box:first-child {
  border-left: 0 none;
}

.file-details-box.separated-details {
  margin-left: 15px;
  border: 1px solid #e6e6e6;
}

.file-details-box.separated-details:first-child {
  margin-left: 0;
}

.file-details-box > * {
  -ms-flex-positive: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100%;
  -ms-preferred-size: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.file-details-header {
  position: relative;
  height: 127px;
  padding: 0 15px;
  background: #f1f1f1;
}

.main-header {
  background: #ffb807;
}

.file-details-header.bg-white {
  background: #fff;
}

.file-details-header.simple-header {
  height: auto;
  padding: 13px 20px;
  background-repeat: no-repeat;
  background-position: 70px 50%;
  font-size: 14px;
  font-weight: 500;
}

.file-details-header.icon-video-camera {
  background-image: url("./img/icon-video-camera.png");
  background-position: 70px 0;
}

.file-details-header.icon-info {
  background-image: url("./img/icon-info.png");
  background-position: 70px -5px;
}

.separated-details .file-details-body {
  padding: 14px 20px;
  background: #fbfbfb;
}

.file-details-detail {
  padding: 20px 0 10px;
  text-align: center;
}

.file-details-header .file-details-detail {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding-bottom: 25px;
  transform: translateY(-50%);
}

.file-details-label {
  display: block;
  text-transform: uppercase;
  color: #a1a1a1;
}

.main-header .file-details-label {
  margin-bottom: 5px;
  color: #9f7304;
}

.file-details-value {
  font-size: 16px;
  overflow-wrap: anywhere;
}

.file-details-header .file-details-value {
  font-size: 24px;
  line-height: 1em;
}

.file-format {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 72px;
  height: 72px;
  margin-left: -10px;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  font-size: 24px;
  line-height: 68px;
  color: #bcbcbc;
}

.file-format:first-child {
  margin-left: 0;
  margin-right: -10px;
}

.file-format.active {
  z-index: 2;
  border-color: #2f2f2f;
  background: #2f2f2f;
  color: #fff;
}

.file-details-list-element {
  padding: 6px 0;
}

.file-details-list-label {
  -moz-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 160px;
  flex-basis: 160px;
  width: 160px;
  padding-right: 10px;
  text-transform: uppercase;
  color: #7c7c7c;
}

.file-details-list-value {
  font-weight: 500;
  word-break: break-all;
}

.mdl-dialog__actions {
  padding: 25px 0 0;
}

.mdl-dialog__actions.pt-0 {
  padding-top: 0;
}

.mdl-dialog__actions .dialog-close:focus:not(:active) {
  box-shadow: none;
}

dialog + .backdrop,
dialog::-webkit-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

dialog + .backdrop,
dialog::backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.item-poczatek-compare {
  width: 70px;
}

.item-nazwa-compare {
  width: 130px;
}

.item-pozycja-compare {
  width: 65px;
  max-width: 75px;
}

.item-idtvn-compare {
  width: 65px;
}

.item-idpartnera-compare {
  width: 75px;
}

.item-dl-compare {
  width: 75px;
}

.item-format-compare {
  width: 75px;
}

.item-typ-compare {
  width: 75px;
}

.item-piwo-compare {
  width: 75px;
}

.item-hfss-compare {
  width: 75px;
}

.compare-modify-alert {
  background: #FFFF33;
}

.compare-added-alert {
  background: #00CC33;
}

.table-body .toggle-row-body .table-comparing-row-deleted {
  background: #FFCCCC;
}

.table-body .toggle-row-body .table-item-deleted {
  border-color: #FFCCCC;
  background: #FFCCCC;
  text-align: center;
  padding: 5px;
}

.table-body .toggle-row-body .table-comparing-row-modify {
  background: #FFFFCC;
}

.table-body .toggle-row-body .table-item-modify {
  border-color: #FFFFCC;
  background: #FFFFCC;
  text-align: center;
}

.table-body .toggle-row-body .table-comparing-row-added {
  background: #99FF99;
}

.table-body .toggle-row-body .table-item-added {
  border-color: #99FF99;
  background: #99FF99;
  text-align: center;
  padding: 5px;
}

.comparing-deleted-arrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #FF0000;
}

.comparing-deleted-label {
  height: 22px;
  margin-left: 11px;
  padding: 4px 7px;
  background: #FF0000;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

.comparing-modify-arrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #3299CC;
}

.comparing-modify-label {
  height: 22px;
  margin-left: 11px;
  padding: 4px 7px;
  background: #3299CC;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

.comparing-added-arrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #00CC33;
}

.comparing-added-label {
  height: 22px;
  margin-left: 11px;
  padding: 4px 7px;
  background: #00CC33;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

.version-spacer {
  width: 4px;
  background-color: #bcbcbc;
}

.field {
  font-weight: 400 !important;
  height: 29px;
}

/* -- Material Design Table style -------------- */

.shadow-z-1 {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: #fff;
}

.table > thead > tr,
.table > tbody > tr,
.table > tfoot > tr {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  text-align: left;
  padding: 1.6rem;
  vertical-align: top;
  border-top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.table > thead > tr > th {
  font-weight: 400;
  color: #757575;
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.table .table {
  background-color: #fff;
}

.table .no-border {
  border: 0;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}

.table-bordered {
  border: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 768px) {
  .table-responsive-vertical > .table {
    margin-bottom: 0;
    background-color: transparent;
  }

  .table-responsive-vertical > .table > thead,
  .table-responsive-vertical > .table > tfoot {
    display: none;
  }

  .table-responsive-vertical > .table > tbody {
    display: block;
  }

  .table-responsive-vertical > .table > tbody > tr {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-bottom: 1.6rem;
  }

  .table-responsive-vertical > .table > tbody > tr > td {
    background-color: #fff;
    display: block;
    vertical-align: middle;
    text-align: right;
  }

  .table-responsive-vertical > .table > tbody > tr > td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: inherit;
    font-weight: 400;
    color: #757575;
  }

  .table-responsive-vertical.shadow-z-1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .table-responsive-vertical.shadow-z-1 > .table > tbody > tr {
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  }

  .table-responsive-vertical > .table-bordered {
    border: 0;
  }

  .table-responsive-vertical > .table-bordered > tbody > tr > td {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .table-responsive-vertical > .table-bordered > tbody > tr > td:last-child {
    border-bottom: 0;
  }

  .table-responsive-vertical > .table-striped > tbody > tr > td,
  .table-responsive-vertical > .table-striped > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical > .table-striped > tbody > tr > td:nth-child(odd) {
    background-color: #f5f5f5;
  }

  .table-responsive-vertical > .table-hover > tbody > tr:hover > td,
  .table-responsive-vertical > .table-hover > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical > .table-hover > tbody > tr > td:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.table-striped.table-mc-red > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > th {
  background-color: #fde0dc;
}

.table-hover.table-mc-red > tbody > tr:hover > td,
.table-hover.table-mc-red > tbody > tr:hover > th {
  background-color: #f9bdbb;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-red > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td:nth-child(odd) {
    background-color: #fde0dc;
  }

  .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-red > tbody > tr > td:hover {
    background-color: #f9bdbb;
  }
}

.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > th {
  background-color: #fce4ec;
}

.table-hover.table-mc-pink > tbody > tr:hover > td,
.table-hover.table-mc-pink > tbody > tr:hover > th {
  background-color: #f8bbd0;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td:nth-child(odd) {
    background-color: #fce4ec;
  }

  .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr > td:hover {
    background-color: #f8bbd0;
  }
}

.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > th {
  background-color: #f3e5f5;
}

.table-hover.table-mc-purple > tbody > tr:hover > td,
.table-hover.table-mc-purple > tbody > tr:hover > th {
  background-color: #e1bee7;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td:nth-child(odd) {
    background-color: #f3e5f5;
  }

  .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr > td:hover {
    background-color: #e1bee7;
  }
}

.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > th {
  background-color: #ede7f6;
}

.table-hover.table-mc-deep-purple > tbody > tr:hover > td,
.table-hover.table-mc-deep-purple > tbody > tr:hover > th {
  background-color: #d1c4e9;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr > td:nth-child(odd) {
    background-color: #ede7f6;
  }

  .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr > td:hover {
    background-color: #d1c4e9;
  }
}

.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > th {
  background-color: #e8eaf6;
}

.table-hover.table-mc-indigo > tbody > tr:hover > td,
.table-hover.table-mc-indigo > tbody > tr:hover > th {
  background-color: #c5cae9;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td:nth-child(odd) {
    background-color: #e8eaf6;
  }

  .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr > td:hover {
    background-color: #c5cae9;
  }
}

.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-blue > tbody > tr:nth-child(odd) > th {
  background-color: #e7e9fd;
}

.table-hover.table-mc-blue > tbody > tr:hover > td,
.table-hover.table-mc-blue > tbody > tr:hover > th {
  background-color: #d0d9ff;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td:nth-child(odd) {
    background-color: #e7e9fd;
  }

  .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr > td:hover {
    background-color: #d0d9ff;
  }
}

.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > th {
  background-color: #e1f5fe;
}

.table-hover.table-mc-light-blue > tbody > tr:hover > td,
.table-hover.table-mc-light-blue > tbody > tr:hover > th {
  background-color: #e8e8e8;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr > td:nth-child(odd) {
    background-color: #e1f5fe;
  }

  .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr > td:hover {
    background-color: #b3e5fc;
  }
}

.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > th {
  background-color: #e0f7fa;
}

.table-hover.table-mc-cyan > tbody > tr:hover > td,
.table-hover.table-mc-cyan > tbody > tr:hover > th {
  background-color: #b2ebf2;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td:nth-child(odd) {
    background-color: #e0f7fa;
  }

  .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr > td:hover {
    background-color: #b2ebf2;
  }
}

.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > th {
  background-color: #e0f2f1;
}

.table-hover.table-mc-teal > tbody > tr:hover > td,
.table-hover.table-mc-teal > tbody > tr:hover > th {
  background-color: #b2dfdb;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td:nth-child(odd) {
    background-color: #e0f2f1;
  }

  .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr > td:hover {
    background-color: #b2dfdb;
  }
}

.table-striped.table-mc-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > th {
  background-color: #d0f8ce;
}

.table-hover.table-mc-green > tbody > tr:hover > td,
.table-hover.table-mc-green > tbody > tr:hover > th {
  background-color: #a3e9a4;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-green > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td:nth-child(odd) {
    background-color: #d0f8ce;
  }

  .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-green > tbody > tr > td:hover {
    background-color: #a3e9a4;
  }
}

.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > th {
  background-color: #f1f8e9;
}

.table-hover.table-mc-light-green > tbody > tr:hover > td,
.table-hover.table-mc-light-green > tbody > tr:hover > th {
  background-color: #dcedc8;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr > td:nth-child(odd) {
    background-color: #f1f8e9;
  }

  .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr > td:hover {
    background-color: #dcedc8;
  }
}

.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > th {
  background-color: #f9fbe7;
}

.table-hover.table-mc-lime > tbody > tr:hover > td,
.table-hover.table-mc-lime > tbody > tr:hover > th {
  background-color: #f0f4c3;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td:nth-child(odd) {
    background-color: #f9fbe7;
  }

  .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr > td:hover {
    background-color: #f0f4c3;
  }
}

.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > th {
  background-color: #fffde7;
}

.table-hover.table-mc-yellow > tbody > tr:hover > td,
.table-hover.table-mc-yellow > tbody > tr:hover > th {
  background-color: #fff9c4;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td:nth-child(odd) {
    background-color: #fffde7;
  }

  .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr > td:hover {
    background-color: #fff9c4;
  }
}

.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > th {
  background-color: #fff8e1;
}

.table-hover.table-mc-amber > tbody > tr:hover > td,
.table-hover.table-mc-amber > tbody > tr:hover > th {
  background-color: #ffecb3;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td:nth-child(odd) {
    background-color: #fff8e1;
  }

  .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr > td:hover {
    background-color: #ffecb3;
  }
}

.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > th {
  background-color: #fff3e0;
}

.table-hover.table-mc-orange > tbody > tr:hover > td,
.table-hover.table-mc-orange > tbody > tr:hover > th {
  background-color: #ffe0b2;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td:nth-child(odd) {
    background-color: #fff3e0;
  }

  .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr > td:hover {
    background-color: #ffe0b2;
  }
}

.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > th {
  background-color: #fbe9e7;
}

.table-hover.table-mc-deep-orange > tbody > tr:hover > td,
.table-hover.table-mc-deep-orange > tbody > tr:hover > th {
  background-color: #ffccbc;
}

@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr > td,
  .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }

  .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr > td:nth-child(odd) {
    background-color: #fbe9e7;
  }

  .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr:hover {
    background-color: #fff;
  }

  .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr > td:hover {
    background-color: #ffccbc;
  }
}

/* -- User card  -------------- */

.user-card-wide.mdl-card {
  width: 512px;
}

.user-card-wide > .mdl-card__title {
  color: #fff;
  /* height: 176px;
   background: url("./assets/welcome_card2.jpg") center / cover;*/
  height: 88px;
  background: url("./assets/user-popup.png") center / cover;
}

.user-card-wide > .mdl-card__menu {
  color: #fff;
}

/* -- Channel card  -------------- */

.channel-card-wide.mdl-card, .channel_group-card-wide.mdl-card, .profile-card-wide.mdl-card, .import-card-wide.mdl-card {
  width: 512px;
}

.channel-card-wide > .mdl-card__title {
  color: #fff;
  height: 88px;
  background: url("./assets/channel-popup.png") center / cover;
}

.channel_group-card-wide > .mdl-card__title {
  color: #fff;
  height: 88px;
  background: url("./assets/channel-group-popup.png") center / cover;
}

.profile-card-wide > .mdl-card__title {
  color: #fff;
  height: 88px;
  background: url("./assets/profile-popup.png") center / cover;
}

.import-card-wide > .mdl-card__title {
  color: #fff;
  height: 88px;
  background: url("./assets/upload-popup.png") center / cover;
}

.channel-card-wide > .mdl-card__menu, .channel_group-card-wide > .mdl-card__menu, .profile-card-wide > .mdl-card__menu, .import-card-wide > .mdl-card__menu {
  color: #fff;
}

.channel-card-wide .mdl-card__title-text, .channel_group-card-wide .mdl-card__title-text, .profile-card-wide .mdl-card__title-text, .user-card-wide .mdl-card__title-text, .import-card-wide .mdl-card__title-text {
  position: relative;
  top: -10px;
  left: 80px;
}

.datepicker div input {
  font-family: 'Oswald';
  font-weight: 500;
}

.datepicker-disabled div input {
  color: #999 !important;
  background-color: #f5f5f5 !important;
}

/*GROWL*/

.ui-growl {
  position: fixed;
  top: 50%;
  left: 50%;
  left: calc(50% - 200px);
  min-width: 400px;
  z-index: 1000000000 !important;
}

@media screen and (max-width: 900px) {
  .ui-growl {
    width: 98%;
    left: 1%;
    min-width: 200px;
  }
}

.ui-growl-image {
  display: none;
}

.ui-growl-item .ui-growl-message {
  margin-left: 1em;
}

.ui-growl-title {
  display: none !important;
}

.ui-growl-item .ui-growl-message p {
  color: inherit;
  font-size: 1.3em;
  padding: 0 0 1em 0;
  margin: 1em 0 0;
}

.ui-growl-icon-close {
  top: 1em;
  right: 1em;
  font-weight: 100;
  font-size: 1.3em
}

.search-group--buttons {
  display: flex;
  justify-content: space-between;

}

/*AUTOCOMPLETE*/
.search-groups--autocomplete .ui-widget {
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  /*text-transform: uppercase;*/

}

.search-groups--autocomplete .ui-inputtext, .weekpicker-box .datepicker__input {
  height: 26px;
}

.search-groups--autocomplete.filter-channel .ui-inputtext, .weekpicker-box .datepicker__input {
  height: 29px;
}

.search-groups--autocomplete.filter-channel .ui-inputtext, .playlist-weekpicker-box-height .datepicker__input {
  height: 26px;
}

.weekpicker-box .datepicker__input {
  width: 120px;
}

.weekpicker-box .datepicker__calendar__nav__chevron {
  vertical-align: top;
}

.search-groups--autocomplete:not(.disable-all) .ui-autocomplete-panel .ui-autocomplete-list-item:last-child {
  color: #1a5eab;
  text-decoration: underline;
  font-size: 14px;
}

.search-groups--autocomplete .ui-corner-all {
  border-radius: 1px;
}

.search-groups--autocomplete .ui-state-highlight {
  color: inherit;
  background-color: #f6f6f6;
}

.search-groups--autocomplete .ui-inputtext.ui-state-focus, .ui-inputtext:focus {
  box-shadow: none;
}

dialog::backdrop {
  background: transparent !important;
}

.search-groups--autocomplete .ui-corner-all {
  margin-left: 0;
  list-style-type: none;
}

.mdl-tabs__tab {
  cursor: pointer;
}

.custom-panel--header {
  display: flex;
  width: 100%;
  padding: 5px;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.weekpicker-box .nav-prev-next-box {
  float: right;
  margin-left: 5px;
}

.custom-panel.flex-column .custom-chips {
  width: 100%;
  padding: 5px;
}

.mdl-button {
  cursor: pointer;
}

.mdl-button--grayborder {
  border: 1px solid #c0c0c0;
}

/*LOADER*/
.ttm-loader > div {
  margin: auto;
}

.loader .ball-spin-fade-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader .ttm-loader {
  background-color: rgb(109, 111, 113);
  z-index: 1002;
}

.item-poczatek.sort-item {
  width: 130px;
}

.item-poczatek.sort-item button {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.item-nazwa.sort-item button {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.material-icons {
  cursor: pointer;
}

/* Regulamin */

.agreement-panel {
  width: auto;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  background: #fff;
  padding-bottom: 50px;
}

.agreement-panel .field-box {
  min-height: 30px;
}

.agreement-panel .field-box label {
  font-size: 17px;
  font-weight: 100;
}

.agreement-panel .field-box label a {
  font-size: 17px;
  text-decoration: underline;
}

.mdl-tabs .mdl-tabs__tab-bar .mdl-tabs__tab {
  color: #b1b1b1;
}

.mdl-tabs .mdl-tabs__tab-bar .mdl-tabs__tab.is-active {
  color: #2f2f2f;
}

.mdl-button .material-icons.small {
  font-size: 12px;
  vertical-align: baseline;
}

.mdl-tabs .dialog-content {
  border: none;
}

.column-header-hover:hover {
  color: #00b0ff;
  cursor: pointer;
}

.column-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
}

.column-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
}

.column-header-inner-container {
  flex: 1 1 0;
  -ms-flex: 1 1;
  min-width: 0px;
}

.column-header-content-container {
  display: flex
}

.column-header-arrow {
  flex: 0 0 auto
}

sort-arrow {
  height: 24px;
  width: 24px;
}

.bottom-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 50px;
}

/*LIFERAY FIXES*/

.aui .mb-5 {
  margin-bottom: 5px !important;
}

.aui .ml-5 {
  margin-left: 5px !important;
}

.aui .mr-5 {
  margin-right: 5px !important;
}

.aui .alert-info, .aui .portlet-msg-help, .aui .portlet-msg-info, .aui .portlet-msg-progress {
  border: 0;
  background-color: #1a5eab;
  color: #fff;
  font-size: 21px;
  font-weight: 300;
}

.aui .alert, .aui .portlet-msg-alert, .aui .portlet-msg-error, .aui .portlet-msg-help, .aui .portlet-msg-info, .aui .portlet-msg-progress, .aui .portlet-msg-success {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

legend.fieldset-legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 40px;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

legend.fieldset-legend span {
  font-size: 21px;
}

label.control-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.button-holder button {
  font-size: 14px;
}

.form {
  margin: 40px 20px 20px 20px;
}

.checkbox-group-box {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}

.checkbox-single-box {
  display: flex;
  justify-content: flex-start;
  padding: 6px 2px;
}

.checkbox-single-description-box {
  padding-left: 30px;
  word-break: break-word;
  white-space: pre-wrap;
}

/*PRIMEENG CHECHBOX OVERRIDE*/

.ui-chkbox-box.ui-state-focus {
  box-shadow: none;
}

.ui-chkbox .ui-chkbox-box {
  width: 1.75em;
  height: 1.75em;
  line-height: 1.75em;
  border-radius: 2px;
  text-align: center;
}

.ui-chkbox-icon.fa.fa-check {
  /*  content: '\2713';
    font-family: "Oswald", sans-serif;*/
  display: block;
  background: url("img/icons/check.png") no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.ui-chkbox-box.ui-state-active, .ui-chkbox-box.ui-state-focus.ui-state-active {
  background: #2f2f2f;
}

.ui-chkbox-box.ui-state-active {
  border: 1px solid #ddd;
}

.ui-chkbox-box.ui-state-focus {
  background: transparent;
}

.field-box input:disabled {
  background-color: #f5f5f5;
  color: #999;
  pointer-events: none;
}

.profiles-action-box button {
  width: 100%;
}

#profilesTable td {
  vertical-align: middle;
}

#profilesTable td:not(.no-results-found) .material-icons {
  font-size: 16px;
}

#profilesTable td .material-icons.yes {
  color: #1a5eab;
}

/*PRIMENG ACCORDION*/
.ui-accordion .ui-accordion-header a {
  font-family: Oswald, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}

.ui-accordion-header span.fa.fa-fw.fa-caret-right {
  display: block;
  background: url("img/icons/expand_more.png") no-repeat;
  width: 20px;
  height: 20px;

}

.ui-accordion-header span.fa.fa-fw.fa-caret-down {
  display: block;
  background: url("img/icons/expand_less.png") no-repeat;
  width: 20px;
  height: 20px;
}

.ui-accordion .ui-cornell-all {
  border-radius: 2px;
}

.ui-accordion .ui-accordion-header.ui-state-active {
  border-top-color: transparent;
}

.ui-accordion .ui-accordion-header .fa {
  position: absolute;
  left: 2px;
  top: 40%;
  margin-top: -.5em;
}

.ui-accordion .ui-accordion-content {
  font-family: Oswald, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}

.nice-select ul.long--list {
  height: 120px;
  overflow: auto;
}

.file--ext .nice-select {
  min-width: 150px;
}

.channel-filter--select .nice-select {
  height: 29px;
}

.mt-5 {
  margin-top: 5px;
}

.no-results-found {
  text-align: center !important;
  text-transform: uppercase;
  font-size: 24px !important;
  font-family: Oswald, sans-serif;
  font-weight: 400;
  color: #ddd;
}

.no-results-found.smaller {
  font-size: 14px !important;
}

.no-results-found.smaller .material-icons {
  font-size: 14px !important;
}

.text-max {
  max-width: 400px;
  word-break: break-all;
}

.playlist-detail-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 35%;
  height: 24px;
  margin: 0;
  padding: 0;
}

.user-config-search-pannel-button {
  margin-bottom: auto;
  height: 29px
}

.attachbox {
  padding: 5px 4px;
  background: #f9f9f9;
  margin: 6px 0 0 0;
  border-radius: 2px;
}

.attach-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.attach-image, .attach-file {
  width: 100%;
  padding: 18px 10px 14px 10px;
  background: #f3f3f3;
  border: 2px dashed #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 2px;
}

.attach-title {
  color: #2f2f2f;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 12px 0;
}

.attachbox-drop {
  opacity: 0.5 !important;
  border: 2px dashed #2f2f2f !important
}

.attach-inputbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.attach-label {
  color: #282828;
  text-transform: uppercase;
  padding: 10px 13px;
  background: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);;
  display: block;
  cursor: pointer;
  z-index: 9;
}

.attach-label:hover {
  background: #f5f5f5;
}

.attach-txt-or {
  color: #626262;
  text-transform: uppercase;
  margin: 0 19px 0 14px;
}

.attach-txt-drop {
  color: #282828;
  text-transform: uppercase;
}

.attach-preview {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

.attach-preview--img {
  text-align: center;
  padding: 20px 0 20px 0;
}

.attach-preview--img img {
  width: 200px;
  height: auto;
}

.attach-preview--title {
  text-align: center;
}

.attach-preview--title p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui-accordion .ui-accordion-header {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}

.ui-accordion .ui-accordion-header.ui-state-active {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}

.ui-accordion .ui-accordion-content {
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.user-profile-search-input-name {
  height: 26px;
}

.user-profile-search-input {
  height: 29px !important;
}

.playlist-input-height {
  height: 29px;
}

.playlist-datepicker-margin {
  margin-right: 32px;
}

.element-to-right-margin {
  margin-left: auto;
}

.element-to-left-margin {
  margin-left: auto;
}

.profile-table {
  display: inline-block;
  width: 100%;
}

.profile-header-label {
  display: inline;
}

.profile-header-label-selected {
  max-width: 60px;
  display: inline;
}

.profile-header-arrow {
  width: 100%;
  margin-left: 10px;
  display: inline-block;
}

.channel-popup-footer-warning, .channel-group-popup-footer-warning {
  margin-bottom: auto;
  margin-top: auto;
  float: left;
  width: 80%;
  padding-left: 35px;
  padding-top: 5px
}

.channel-popup-footer-button-container, .channel-group-popup-footer-button-container {
  float: left;
  width: 20%;
  text-align: right
}

.channel-popup-footer-button, .channel-group-popup-footer-button {
  display: inline-block;
  margin-left: auto
}

.channel-change-pass-info {
  float: right;
}

.profile-change-pass-info {
  float: right;
}

.mb-12 {
  margin-bottom: 12px;
}

.field--info {
  display: flex;
  align-items: center;
  white-space: normal;
}

.field--info span {
  text-align: justify;
  color: #1a5eab;
}

.add-extension {
  height: 29px;
  vertical-align: middle;
}

.user-search-input-margin-first {
  margin-right: auto;
}

.user-search-input-margin-bottom {
  margin-right: auto;
  margin-left: auto;
}

.user-search-new-user-button-margin {
  margin-right: 0px;
}

.user-search-role-input {
  width: 110px;
}

.notify-configuration {
  display: flex;
  justify-content: space-between;
}

.notify-configuration-info:not(.ui-accordion-content) {
  margin-bottom: 0.5em;
  font-family: Oswald, sans-serif;
  font-size: 16px;
}

/*.notify-configuration-info ul > li{
  font-family: Oswald, sans-serif;
  font-size: 16px;
}*/

.notify-configuration-info .ui-accordion-content span {
  color: #1a5eab;
}

.notify-configuration-info .ui-accordion-content {
  margin-bottom: 20px !important;
}

.profile-table-cell-padding {
  padding: 10px !important;
}

.profile-table-button-width {
  max-width: 75px;
}

.channel-group-popup-footer-height {
  height: 48px;
}

.user-table-action-button-container {
  text-align: center;
}

.user-table-action-button {
  width: 80%;
}

.profiles-search-name-input {
  height: 26px;
}

/*DROPDOWN PRIMENG*/
.user-mail-channel-form .ui-dropdown {
  width: 100% !important;
}

.user-mail-channel-form .ui-dropdown .ui-corner-all {
  border-radius: 1px !important;
}

.user-mail-channel-form .ui-widget {
  font-family: Oswald, sans-serif !important;
  font-size: 14px;
}

.user-mail-channel-form .ui-state-highlight {
  color: inherit;
  background-color: #f6f6f6;
}

.user-mail-channel-form .ui-dropdown.ui-state-focus {
  box-shadow: none;
}

.user-mail-channel-form .ui-dropdown-trigger.ui-corner-right {
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.user-mail-channel-form .ui-dropdown-trigger.ui-corner-right span::after {
  content: "\25BC";
  font-size: 8px;
  position: relative;
  top: 0px;
  right: -2px;
}

.user-mail-channel-form .ui-dropdown-items-wrapper {
  max-height: 100px !important;
}

.notify-configuration-info.flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.notify-configuration-info.mb-25 {
  margin-bottom: 25px;
}

/*PRIMENG INPUT MASK*/
.block-field .ui-inputtext {
  height: 29px !important;
}

.block-field input[type="text"] {
  font-weight: 400;
}

.channel-group-action-header {
  width: 20%;
}

.user-config-popup-profile-text {
  font-size: 12px;
  padding-left: 6px;
  color: #000;
}

.playlist-compare-block-type-table-header {
  padding-left: 0;
  padding-right: 0;
  min-width: 90px;
}

.portlet-content .alert-error {
  text-align: center;
  margin-top: 100px;
  font-size: 13px;
  line-height: 23px;
}

.playlist_app {
  min-height: 90vh;
}

.text-center {
  text-align: center;
}

.text-center-imp {
  text-align: center !important;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.powered-by {
  float: right;
  margin-right: 20px;
}

.regulations {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px;
}

/*STATYSTYKI START*/

.stats-panel {
  margin-left: 10px;
  min-width: 1000px;
  width: 100%;
}

.stats-panel-left-part {
  display: inline-block;
  width: 80%;
}

.stats-panel-right-part {
  display: inline;
  width: 19%;
}

.stats-panel-col {
  display: inline-block;
  width: 18%;
}

.stats-panel-cell {
  font-size: larger;
}

/*Elementy playlisty*/
.stats-table .stats-table-cell {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.playlist-element-search-pannel-button {
  margin-bottom: auto;
  height: 29px
}

.playlist-elements-filter-settings-box {
  min-width: 180px;
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid gray;
}

.playlist-elements-action-btn-container {
  width: 15%;
  z-index: 1;
  /*display: table;*/
  margin-left: auto;
  margin-right: auto;
}

.custom-dropdown .p-dropdown-label {
  padding: 0 9px 9px; /* Specjalny padding dla zaznaczonego elementu */
  height: 29px; /* Wysokość dla zaznaczonego elementu */
  line-height: 25px; /* Wyrównanie tekstu w pionie */
}


.playlist-elements-action-btn-container-inner {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.playlist-elements-action-btn {
  width: 100%;
}

.playlist-element-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}

.playlist-element-date-input material-datepicker .datepicker {
  width: 46.5%;
}

.playlist-element-date-input material-datepicker .datepicker input {
  width: 100%;
}

.playlist-element-input {
  width: 95%;
}

.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*Materialy niepobrane*/
/*Elementy playlisty*/
.missing-table .missing-table-cell {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.missing-material-search-pannel-button {
  margin-bottom: auto;
  height: 29px;
}

.playlist-elements-page-size-list {
  height: 29px
}

.playlist-elements-search-pannel-input span {
  width: 95%;
}

.playlist-elements-search-pannel-input span input {
  height: 29px !important;
  width: 100%;
  color: rgb(51, 51, 51) !important;
}

.column-width-5 {
  width: 5%;
}

.column-width-8 {
  width: 8%;
}


.column-width-10 {
  width: 10%;
}

.column-width-12 {
  width: 12%;
}

.column-width-24 {
  width: 24%;
}

.column-width-38 {
  width: 38%;
}

.mr-5-imp {
  margin-right: 5px !important;
}

/*STATYSTYKI END*/

/*Lista playlist*/
.playlist-header {
  width: 100%;
}

.playlist-header-title {
  float: left;
}

.timer-wheel-box {
  float: right;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 7px;
}


/*Timer na liscie playlist*/
.perCirc {
  position: relative;
  text-align: center;
  width: 85px;
  height: 85px;
  border-radius: 100%;
  background-color: #275eab;
  background-image: linear-gradient(91deg, transparent 50%, #ccc 50%), linear-gradient(90deg, #ccc 50%, transparent 50%);
  color: #777;
}

.perCirc:hover {
  cursor: pointer;
  color: #2c2c2c;
}

.perCirc .perCircInner {
  position: relative;
  top: 10px;
  left: 10px;
  text-align: center;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  background-color: #eee;
}

.perCirc .perCircInner div {
  position: relative;
  top: 15px;
}

.perCirc .perCircStat {
  font-size: 12px;
  line-height: 1em;
}


/*PANEL KONTROLNY ------------*/
.control-panel-page {
  width: 100%;
  height: 100%;
}


.cp-search-container {
  width: auto;
  min-height: auto;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  background: #fff;
}

.cp-search-row {
  display: flex;
  margin-left: 5px;
}

.cp-channel-group-and-channel {
  min-width: 180px;
  width: 13%;
  margin-right: 8px;
}

.cp-date-from-to-and-file-compare-and-status {
  margin-right: 8px;
}

.cp-file-compare-and-status .nice-select {
  margin-right: 0;
}

.cp-file-action {
  padding-left: 10px;
  border-left: 1px solid black;
  margin-left: auto;
}

.cp-table-container {

}

.cp-table {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border: 1px solid #2f2f2f;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  border-collapse: collapse;
}


.cp-table td {
  border-bottom: 1px solid #2f2f2f;
}

/*KOLUMNY*/
.cp-column-material-name {
  width: 23%;
  max-width: 100px;
}

.cp-column-material-type {
  width: 7%;
  max-width: 80px;
  text-align: center;
}

.cp-column-file-name-db {
  width: 5%;
  max-width: 100px;
}

.cp-column-processing-status {
  width: 7%;
  text-align: center;
}

.cp-column-retry-counter {
  width: 3%;
  text-align: center;
}

.cp-column-action {
  width: 3%;
  text-align: center;
  border-right: 3px solid #2f2f2f;
}

.cp-column-file-name-ftp {
  width: 5%;
  max-width: 100px;
}

.cp-column-file-ftp-host {
  width: 13%;
  text-align: center;
  border-left: 1px solid #2f2f2f;
}

.cp-column-file-ftp-port {
  width: 2%;
  text-align: center;
}

.cp-column-file-ftp-user {
  width: 4%;
  text-align: center;
}

.cp-column-file-ftp-dir {
  width: 4%;
  text-align: center;
}

.cp-column-date {
  width: 9%;
  text-align: center;
}

.cp-column-file-size {
  width: 8%;
  text-align: center;
}

.cp-column-full-row {
  text-align: center;
  font-size: larger;
}

.cp-column-no-file-info {
  text-align: center;
  font-weight: bold;
}

.cp-column-virtual-material {
  text-align: center;
  font-weight: bold;
}

/*KOLUMNY END*/

.cp-table-header-db-part {
  border-right: 3px solid #2f2f2f;
  border-top: 1px solid #2f2f2f;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.cp-table-header-ftp-part {
  border-top: 1px solid #2f2f2f;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.cp-table-header-row {
  background-color: #2f2f2f;
  color: white;
}

.cp-table-header-cell {
  padding: 8px;
}

.cp-table-data-row {

}

.cp-table-data-cell {
  padding: 7px 8px;
  word-wrap: break-word;
}

.cp-table-diff-size {
  background-color: rgba(255, 1, 1, 0.24);
}

.cp-equals-button {
  font-size: 25px;
}

.cp-not-equals-button {
  font-size: 20px;
}

.select-input-height input {
  height: 30px !important;
}

.cp-inline-input {
  display: inline-table;
  margin-right: 10px;
}

.cp-input-border {
  border: 1px solid rgb(214, 214, 214);
}

.cp-checkbox-input {
  height: 29px;
  width: 20px;
  vertical-align: middle;
  margin: 2px;
}

.cp-table-action-btn {
  min-width: 10px;
  width: 30px;
  height: 30px;
}


.cp-table-row-status-both {
  background-color: white;
}

.cp-table-row-status-only-db {
  background-color: rgba(255, 1, 1, 0.24);
}


.cp-table-row-status-only-ftp {
  background-color: rgba(209, 196, 122, 0.62);
}

.cp-search-error-msg {
  font-size: larger;
  color: red;
}


.cp-search-buttons {
  margin-bottom: auto;
  height: 29px;
  margin-left: 0 !important;
}


.cp-search-buttons-active {
  background: rgba(185, 185, 185, 0.75);
  font-weight: bold;
}

.cp-choose-filters {
  text-align: center !important;
  font-size: 24px !important;
  font-family: Oswald, sans-serif;
  font-weight: 400;
  color: #ddd;
}


/*KONIEC PANEL KONTROLNY ------------*/

.con-btn {
  font-family: "Oswald", sans-serif !important;
}

.ttm-datepicker-font .datepicker input {
  font-family: "Oswald", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}

.active-input-color {
  color: rgb(51, 51, 51) !important;
}

.placeholder-input-color {
  color: rgb(117, 117, 117) !important;
}


/*POPUP IMPORTU PLAYLIST*/
.playlist-import-form-container {
  width: 100%;
}

.playlist-import-form-sources {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

.playlist-import-form-dates {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

.playlist-import-select input {
  height: 28px !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  width: 100%;
}

.playlist-import-datepicker .datepicker {
  width: 100%;
}

.playlist-import-datepicker .datepicker input {
  width: 100%;
  height: 28px;
  border-radius: 0px;
}

.playlist-import-datepicker .datepicker input:hover {
  cursor: pointer;
}

.playlist-import-date-info {
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 12px;
}

.playlist-import-end-info {
  width: 100%;
  text-align: left;
  font-size: larger;
  padding: 5px 0px;
  margin-bottom: 12px;
}

.playlist-import-current-info {
  width: 100%;
  text-align: left;
  font-size: larger;
  padding: 5px 0px;
  margin-bottom: 12px;
}

.playlist-import-done-info {
  width: 100%;
  text-align: center;
  font-size: 17px;
  padding: 15px 0px;
  margin-bottom: 15px;
}

/*lista błędów importu*/
.playlist-import-list-title {
  width: 100%;
  text-align: center;
  font-size: larger;
  border: 1px solid #2f2f2f;
  padding: 5px;
}

.playlist-import-list-container {
  width: 100%;
  border: 1px solid #2f2f2f;
}

.playlist-import-list-header {
  width: 100%;
  background-color: #2f2f2f;
  color: rgb(188, 188, 188);
}

.playlist-import-list {
  max-height: 240px;
  overflow-y: auto;
}

.playlist-import-list-row {
  width: 100%;
  border-bottom: 1px solid #2f2f2f;
}

.playlist-import-list-zero-error {
  width: 100%;
  text-align: center;
  font-size: larger;
  padding: 15px;
}

.playlist-import-list-before-import {
  width: 100%;
  text-align: center;
  font-size: larger;
  padding: 15px;
}

.playlist-import-list-cell {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 5px 5px;
}

.playlist-import-list-cell-channel {
  width: 20%;
}

.playlist-import-list-cell-date {
  width: 15%;
}

.playlist-import-list-cell-description {
  width: 63%;
}

/*PRZYKLEJONY PASEK NA PLAYLISTACH*/
.sticked-playlist-table-header {
  background-color: inherit;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.popup-background-blocker {
  position: fixed;
  top: 0;
  left: 0;
  background: #00000077;
  opacity: 1;
  z-index: 99; /* musi być mniejsze od z-index z klasy .mdl-dialog*/
  height: 100%;
  width: 100%;
}

.popup-content-foregrounder {
  position: relative;
  background-color: #fff;
  z-index: 100; /* musi być większe od z-index z klasy .popup-background-blocker*/
}

.p-datepicker {
  background-color: white !important;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  padding: 5px 10px;
}

.p-monthpicker, .p-yearpicker {
  white-space: pre-wrap;
}

.p-datepicker-title {
  display: flex;
  column-gap: 4px;
}

.p-datepicker-title > * {
  font-weight: 400 !important;
  font-family: "Oswald" !important;
}

table.p-datepicker-calendar td {
  padding: 7px 10px;
}

table.p-datepicker-calendar th {
  padding: 7px 10px;
  color: lightgray;
  text-transform: uppercase;
}

.p-datepicker-today {
  background: #1a5eab;
  color: white;
  border-radius: 30px;
}

.p-datepicker-header {
  padding: 10px 0;
}

.p-toast {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.p-toast-message-content {
  border-radius: 15px;
  padding: 5px;
}

.custom-toast {
  color: white;
  text-align: center;
}

.p-toast-detail {
  font-size: 14px;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: white;
}

.custom-toast-success {
  background-color: #1acb10e6 !important;
  color: white;
  text-align: center;
  border-color: #1acb10e6 !important;
  word-break: break-word;
  font-size: 16px;
}

.custom-toast-info {
  background-color: #00bbffe6 !important;
  color: white;
  text-align: center;
  border-color: #00bbffe6 !important;
  word-break: break-word;
  font-size: 16px;
}

.custom-toast-warning {
  background-color: #ffae00e6 !important;
  color: white;
  text-align: center;
  border-color: #ffae00e6 !important;
  word-break: break-word;
  font-size: 16px;
}

.custom-toast-error {
  background-color: red !important;
  color: white;
  text-align: center;
  border-color: red !important;
  word-break: break-word;
  font-size: 16px;
}

.p-toast-success {
  background-color: rgba(26, 203, 16, 0.9) !important;
  color: white;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: white;
}

.p-toast-info {
  background-color: rgba(0, 187, 255, 0.9) !important;
  color: white;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: white;
}

.p-toast-warning {
  background-color: rgba(255, 174, 0, 0.9) !important;
  color: white;
}

.p-toast-error {
  background-color: red !important;
  color: white;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: white;
}

.p-toast-message-icon, .p-toast-icon-close {
  padding: 5px;
}

.p-autocomplete-panel {
  background-color: white !important;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  padding: 5px 10px;
}

.p-autocomplete-loader {
  right: 4%;
}

.p-checkbox-box {
  border: 1px solid;
  min-height: 15px;
  min-width: 15px;
}

.p-checkbox-label {
  padding-left: 4px;
}

.p-calendar input {
  height: 30px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem;
}

.p-inputtext {
  font-family: "Oswald", sans-serif;
  height: 30px;
  border-radius: 2px;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-color: inherit;
}

.p-inputtext:enabled:hover {
  border-color: inherit;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  font-weight: 400;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  color: #6c757d;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.p-accordion .p-accordion-content {
  color: #6c757d;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.p-blockui {
  z-index: 11;
}

.justify-end {
  justify-content: flex-end !important;
}

.mat-slide-toggle-bar {
  background-color: #00000061;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #1a5eab8a;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1a5eab;
}

.mat-slide-toggle-thumb {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  background-color: #e5e5e5;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

.p-dropdown-item.p-highlight {
  color: #4b5563;
  background-color: #e5e7eb;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #000;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: #000;
  box-shadow: none;
}

.p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext {
  border-color: #000;
  box-shadow: none;
}
